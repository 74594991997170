import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
} from '@material-ui/core';
import { cards } from '../../../state';

const OrderDetails = ({ orderDetails }) => {
  const { name, organizationName, recipients, amount, qty } = orderDetails;
  const total = amount * qty;

  return (
    <Box align="center" width="45%">
      <Card raised elevation={3}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Order Details
          </Typography>
          <List dense>
            <ListItem disableGutters>
              <ListItemText primary="Gift Card:" secondary={`${name}`} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary="Gift Card Amount & Qty:"
                secondary={`$${amount} x ${qty} = $${total}`}
              />
            </ListItem>
            {organizationName && (
              <ListItem disableGutters>
                <ListItemText
                  primary="Nonprofit:"
                  secondary={organizationName}
                />
              </ListItem>
            )}
            {!isEmpty(recipients) && (
              <ListItem disableGutters>
                <ListItemText primary="Recipient:" secondary={recipients} />
              </ListItem>
            )}
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

OrderDetails.propTypes = {
  orderDetails: PropTypes.shape({
    name: PropTypes.string,
    organizationName: PropTypes.string,
    recipients: PropTypes.string,
    amount: PropTypes.number,
    qty: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  orderDetails: cards.selectors.selectSelectedCard(state),
});

export default connect(mapStateToProps)(OrderDetails);
