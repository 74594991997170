import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

import {
  Typography,
  Grid,
  Divider,
  Box,
  Tabs,
  Tab,
  withStyles,
  Container,
} from '@material-ui/core';

import UserInfoSection from './UserInfoSection';
import DetailsSection from './DetailsSection';
// import AddressSection from './AddressSection';

import { auth, enterprise } from '../../../state';
import SEO from '../../seo';

// move to a separate component
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={3}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.oneOfType([PropTypes.any]).isRequired,
  value: PropTypes.oneOfType([PropTypes.any]).isRequired,
};

const a11yProps = (index) => {
  return {
    id: `${index}`,
    'aria-controls': `${index}`,
  };
};

const StyledTab = withStyles({
  root: {
    minWidth: 'auto',
    marginRight: '20px',
    padding: '0',
    textTransform: 'capitalize',
  },
})(Tab);

const Profile = () => {
  // const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [infoEnterprise, setInfoEnterprise] = React.useState({});
  const [enterpriseMembers, setEnterpriseMembers] = React.useState([]);
  const data = useSelector((state) => enterprise.selectors.selectInfo(state));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleGetInfo = async () => {
    if (data) {
      const enterpriseItem = {
        id: data.id,
        name: data.name,
        city: data.city,
        address: data.address,
        country: data.country,
        imageUrl: data.imageUrl,
        website: data.website,
        zip: data.zip,
        state: data.state,
      };

      const members = data.enterpriseMembers || [];

      setInfoEnterprise(enterpriseItem);
      setEnterpriseMembers(members);
    }
  };

  useEffect(() => {
    handleGetInfo();
  }, [data]);

  return (
    <Container disableGutters>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <SEO title="Profile" />
          <Box pb={2}>
            <Typography variant="h6">Profile</Typography>
          </Box>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            aria-label="Select settings tab"
          >
            <StyledTab
              disableRipple
              label="Business"
              {...a11yProps('Business')}
            />
            <StyledTab
              disableRipple
              label="Contact"
              {...a11yProps('ManageUsers')}
            />
          </Tabs>
          <Divider />
          <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
            <TabPanel value={value} index={0}>
              <DetailsSection infoEnterprise={infoEnterprise} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <UserInfoSection enterpriseMembers={enterpriseMembers} />
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </Grid>
    </Container>
  );
};
Profile.defaultProps = {};

Profile.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: auth.selectors.selectUser(state),
});
export default connect(mapStateToProps)(Profile);
