import React from 'react';
import { Grid, Box, CircularProgress } from '@material-ui/core';
import { Field, Formik, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSelector } from 'react-redux';
import CustomInputField from '../../custom/CustomInputField';
import { auth, enterprise } from '../../../state';

const UserInfoSection = () => {
  const user = useSelector(auth.selectors.selectUser);
  const { enterpriseMembers } = useSelector(enterprise.selectors.selectInfo);

  return (
    <Grid container direction="row">
      <Grid item xs={12} md={4}>
        {user.id && enterpriseMembers ? (
          <Formik
            enableReinitialize
            initialValues={{
              ...user,
              phone:
                enterpriseMembers?.find((e) => e.accountId === user.id)?.account
                  ?.phone || '',
            }}
            onSubmit={async () => {}}
          >
            {() => {
              return (
                <Form>
                  <Box pb={2}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      capitalize
                      fullWidth
                      disabled
                      label="First Name"
                      // value={user.firstName}
                      name="firstName"
                      variant="outlined"
                    />
                  </Box>
                  <Box pb={2}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      capitalize
                      fullWidth
                      label="Last Name"
                      disabled
                      // value={user.lastName}
                      name="lastName"
                      variant="outlined"
                    />
                  </Box>
                  <Box pb={2}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      capitalize
                      fullWidth
                      label="Email"
                      disabled
                      // value={user.lastName}
                      name="email"
                      variant="outlined"
                    />
                  </Box>
                  <Box pb={2}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      capitalize
                      fullWidth
                      label="Phone"
                      disabled
                      // value={user.lastName}
                      name="phone"
                      variant="outlined"
                    />
                  </Box>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <CircularProgress size={24} />
        )}
      </Grid>
    </Grid>
  );
};

export default UserInfoSection;
