/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Dialog,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import CSVReader from 'react-csv-reader';
import { Edit, Close } from '@material-ui/icons';
import * as Yup from 'yup';
import SEO from '../../seo';
import { enterprise, businesses, checkout, alert } from '../../../state';
import { TransactionTable } from '../../custom';
import ConfirmationDialog from '../../custom/ConfirmDialog';
import BulkCardDetails from './BulkCardDetails';
import EditRowDialog from './EditRowDialog';

const colsTitles = [
  'First Name',
  'Last Name',
  'Email',
  'Amount',
  'Send Date (YYYY-MM-DD)',
  'Personal Message',
  '',
];

const rowKeys = [
  'First Name',
  'Last Name',
  'Email',
  'Amount',
  'Send Date (YYYY-MM-DD)',
  'Personal Message',
  'Id',
];
const useStyles = makeStyles(() => ({
  fullwidthChild: {
    '& > div': {
      width: '100%',
    },
  },
  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0.5 },
  },
  blink: {
    animationName: '$blinker',
    animationDuration: '1s',
    animationTimingFunction: 'ease',
    animationIterationCount: 'infinite',
  },
  generateButton: {
    backgroundColor: '#284C0D !important',
    color: '#fff !important',
    minWidth: '190px',
  },
}));

const BulkIssue = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [noDataMsg, setNoDataMsg] = React.useState(t('noData'));
  const [edit, setEdit] = React.useState(null);
  // const dispatch = useDispatch();
  // const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const isLoading = useSelector(enterprise.selectors.selectIsLoading);
  const { currency } = useSelector(businesses.selectors.selectBusiness);
  const formData = useSelector(checkout.selectors.selectFormData);
  const mods = {
    Amount: (val) =>
      val &&
      t('cardValue', {
        amount: val,
        formatParams: {
          amount: { currency: currency || 'USD' },
        },
      }),
    Id: (value, row, index) =>
      row.Amount && (
        <Tooltip title="Edit" placement="top" arrow>
          <Edit
            onClick={() => setEdit({ value: row, index })}
            cursor="pointer"
          />
        </Tooltip>
      ),
  };
  const dispatch = useDispatch();
  const [results, setResults] = useState([{}, {}, {}, {}, {}]);
  const openModal = useSelector(alert.selectors.selectModal);
  const setOpenModal = (payload) => dispatch(alert.actions.setModal(payload));
  const [isImported, setImported] = useState(false);
  const handleCsvUpload = (data) => {
    setResults(data);
  };
  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.trim(),
  };

  const downloadCSV = () => {
    fetch('../../../Sample file for bulk gift card issue.csv').then(
      (response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'Sample file for bulk gift card purchase.csv';
          alink.click();
        });
      }
    );
  };

  const saveData = (data, index) => {
    const val = [...results];
    val.splice(index, 1, data);
    setResults(val);
    setEdit(null);
  };
  const [errors, setErrors] = useState([]);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setNoDataMsg(t('noData'));
    }
  }, [isLoading]);

  useEffect(() => {
    if (formData.business === null) {
      setOpenModal(false);
    }
  }, [formData]);
  const searchBox = () =>
    isLoading ? (
      <Skeleton width="50%" />
    ) : (
      <>
        <Grid item>
          <Box>
            <Typography variant="h6">
              {t('totalGiftCardAmount')}:{' '}
              {t('cardValue', {
                amount: results.length
                  ? results.reduce(
                      (a, b) =>
                        Number(a) +
                        Number(
                          (b.Amount ? b.Amount.toString() : '0').match(
                            /(\d+)/
                          )[1]
                        ),
                      0
                    )
                  : 0,
                formatParams: {
                  amount: { currency },
                },
              })}
            </Typography>
          </Box>
        </Grid>
      </>
    );

  const [params, setParams] = useState({ pageSize: 10, page: 1 });

  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, pageSize: event.target.value, page: 1 });
  };

  const pagination = {
    total: results.length,
    pageSize: params.pageSize,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(
      results.slice(
        (params.page - 1) * params.pageSize,
        params.page * params.pageSize
      )
    );
  }, [results, params]);

  const schema = Yup.array().of(
    Yup.object().shape({
      'First Name': Yup.string().required(),
      'Last Name': Yup.string().required(),
      Email: Yup.string().email().required(),
      Amount: Yup.number().required().max(2000),
      'Send Date (YYYY-MM-DD)': Yup.string()
        .matches(
          /^(19|20)\d\d[-/](0[1-9]|1[0-2])[-/](0[1-9]|[12][0-9]|3[01])$/,
          'Invalid date format. Please use YYYY/MM/DD or YYYY-MM-DD format.'
        )
        .nullable(),
    })
  );

  useEffect(() => {
    if (results.length && results[0].Email) {
      schema
        .validate(data, { abortEarly: false })
        .then(() => setErrors([]))
        .catch((err) => {
          setErrors(
            err.errors.map((str) =>
              str
                .replace(/\[(\d+)\]\./g, (match, number) => {
                  return `Row ${Number(number) + 1}: `;
                })
                .replace(
                  /([\d]+)$/,
                  (val) =>
                    `${t('cardValue', {
                      amount: Number(val),
                      formatParams: {
                        amount: { currency: currency || 'USD' },
                      },
                    })}`
                )
            )
          );
        });
    }
  }, [data]);

  const buyGiftCard = () => {
    if (errors.length) {
      setShowError(true);
    } else {
      setOpenModal('bulkIssue');
    }
  };

  return (
    <>
      <SEO title="Detailed Check-in List" />
      <>
        <Box mt={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg="auto">
              <Button variant="contained" color="primary" component="label">
                Import from CSV File
                <CSVReader
                  inputId="CSVReader"
                  inputStyle={{ display: 'none' }}
                  onFileLoaded={handleCsvUpload}
                  parserOptions={parseOptions}
                />
              </Button>
            </Grid>
            <Grid item>
              <Typography
                variant="a"
                color="textSecondary"
                onClick={downloadCSV}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  display: 'flex',
                  verticalAlign: 'center',
                }}
              >
                Sample CSV
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: 'auto' }}>
              <Box ml={1}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.generateButton}
                  onClick={buyGiftCard}
                  disabled={isLoading || !results.length || !results[0].Email}
                >
                  Buy Gift Cards
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>

      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={data}
          mods={mods}
          isLoading={isLoading}
          noDataMsg={noDataMsg}
          searchBox={searchBox}
          pagination={pagination}
        />
      </Box>
      <ConfirmationDialog
        open={isImported}
        setOpen={setImported}
        title=""
        isConfirmation={false}
        content="Store Credits Generated Successfully!"
        actionOk={() => setImported(false)}
      />
      <Dialog
        open={openModal === 'bulkIssue'}
        onClose={() => setOpenModal(false)}
        maxWidth="lg"
        fullWidth
        scroll="paper"
        aria-labelledby="Bulk Issue"
        aria-describedby="Bulk Issue"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{t('bulkIssueGiftCard')}</Box>
            <Box>
              <IconButton onClick={() => setOpenModal(false)}>
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText tabIndex={-1} component="div">
            <BulkCardDetails data={results} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={!!edit && !!edit}
        onClose={() => setEdit(null)}
        maxWidth="sm"
        fullWidth
        scroll="paper"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogTitle>Edit row</DialogTitle>
        <DialogContent dividers>
          <DialogContentText tabIndex={-1} component="div">
            <EditRowDialog data={edit} saveData={saveData} />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={errors.length && showError}
        onClose={() => setShowError(false)}
        maxWidth="sm"
        fullWidth
        scroll="paper"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogTitle>Invalid Gift Card Data</DialogTitle>
        <DialogContent dividers>
          <DialogContentText tabIndex={-1} component="div">
            <Box mb={2}>
              {errors.map((e) => (
                <Typography variant="body2" style={{ color: 'orangered' }}>
                  {e}
                </Typography>
              ))}
            </Box>
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowError(false)}
              >
                OK
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BulkIssue;
