import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Box, Button, Typography } from '@material-ui/core';
// import { PreLoader } from '../../custom';
import { Done } from '@material-ui/icons';
import { cards } from '../../../state';

const Step4 = ({ emptySelectedCard, setActiveStep }) => {
  React.useEffect(() => {
    emptySelectedCard();
  }, []);

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={5}>
        <Box align="center">
          <Box pb={2} align="center">
            <Done style={{ fontSize: '54px' }} />
            <Typography variant="h6">All Done!</Typography>
          </Box>
          <Button onClick={handleReset} variant="contained" color="primary">
            Start Over
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

Step4.propTypes = {
  setActiveStep: PropTypes.func.isRequired,
  emptySelectedCard: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  emptySelectedCard: () => dispatch(cards.actions.emptySelectedCard()),
});

export default connect(null, mapDispatchToProps)(Step4);
