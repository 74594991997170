import React from 'react';
import {
  Box,
  Grid,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import SEO from '../../seo';
import { CustomInputField } from '../../custom';
import { auth, cards, checkout } from '../../../state';
import CardModal from './CardModal';

const phoneRegExp = /^\(*\+*[1-9]{0,3}\)*-*[1-9]{0,3}[-. /]*\(*[2-9]\d{2}\)*[-. /]*\d{3}[-. /]*\d{4} *e*x*t*\.* *\d{0,4}$/;

const validationSchema = () =>
  Yup.object().shape({
    giftFirstName: Yup.string().required('This field is required'),
    giftLastName: Yup.string().required('This field is required'),
    giftEmail: Yup.string()
      .required('This field is required')
      .email('Please enter valid email'),
    phone: Yup.string()
      .required('This field is required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    // amount: Yup.number()
    //   .required('This field is required')
    //   .max(2000, `Amount cannot exceed ${currencySymbol}2000`),
  });

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currencySymbol = '$';
  const defaultForm = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    giftFirstName: '',
    giftLastName: '',
    giftEmail: '',
  };
  const setFormData = (payload) =>
    dispatch(checkout.actions.setFormData(payload));
  const setStep = (payload) => dispatch(checkout.actions.setStep(payload));
  const setSelectedCard = (payload) =>
    dispatch(cards.actions.setSelectedCard(payload));
  const user = useSelector(auth.selectors.selectUser);

  const [showDialog, setShowDialog] = React.useState(false);

  const onSubmit = async (values, formikHelper) => {
    formikHelper.setSubmitting(true);
    await setFormData(values);
    setShowDialog(true);
    formikHelper.setSubmitting(false);
  };

  return (
    <>
      <SEO title="Detail Issue eCard" />
      <Formik
        validationSchema={() => validationSchema(currencySymbol)}
        onSubmit={onSubmit}
        initialValues={{
          ...defaultForm,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        }}
      >
        {({ isSubmitting, handleSubmit, setValues, setErrors }) => {
          const closeDialog = () => {
            setStep(0);
            setShowDialog(false);
            setSelectedCard({});
            setValues({
              ...defaultForm,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
            });
            setTimeout(() => setErrors({}));
          };
          return (
            <>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item container xs={12} md={6} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      trim
                      fullWidth
                      required
                      label={t('firstName')}
                      autoComplete="off"
                      name="giftFirstName"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      trim
                      fullWidth
                      required
                      label={t('lastName')}
                      autoComplete="off"
                      name="giftLastName"
                      variant="outlined"
                    />
                  </Grid>
                  <Box mb={1} />
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      trim
                      fullWidth
                      required
                      label={t('mobilePhone')}
                      autoComplete="off"
                      name="phone"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      trim
                      fullWidth
                      required
                      label={t('emailAddress')}
                      autoComplete="off"
                      name="giftEmail"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      t('issueAGiftCard')
                    )}
                  </Button>
                </Box>
              </Grid>

              <Dialog
                open={showDialog}
                onClose={closeDialog}
                scroll="paper"
                fullWidth
                maxWidth="lg"
              >
                <DialogTitle>{t('issueAGiftCard')}</DialogTitle>
                <DialogContent dividers>
                  <CardModal />
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeDialog} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      </Formik>
    </>
  );
};
