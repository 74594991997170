import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  Box,
  List,
  ListItemText,
  ListItem,
  Checkbox,
  ListItemSecondaryAction,
  // CircularProgress,
  Button,
} from '@material-ui/core';
// import { PreLoader } from '../../custom';
import { cards, alert } from '../../../state';

const Step2 = ({ handleNext, setSelectedCard, openAlert, selectedCard }) => {
  //   if (isLoading) {
  //     return <PreLoader />;
  //   }
  const [checked, setChecked] = React.useState(selectedCard.recipients || '');
  const users = [
    { name: 'David Bowie', email: 'david@pintuna.com' },
    { name: 'Elon Musk', email: 'elon@pintuna.com' },
    { name: 'Roger Do', email: 'roger@pintuna.com' },
    { name: 'Johnie Depp', email: 'johnie@pintuna.com' },
  ];
  const handleSubmit = () => {
    if (checked.length === 0) {
      openAlert({
        message: 'Please select at least 1 recipient',
        severity: 'error',
      });
      return null;
    }
    const payload = { recipients: checked };
    setSelectedCard(payload);
    handleNext();
    return null;
  };

  const handleToggle = (value) => () => {
    setChecked(value);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Box pb={2}>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <Button>Filter</Button>
            </Grid>
            <Grid item xs={7} />
          </Grid>
          <List dense>
            {users.map(({ name, email }, i) => (
              <ListItem
                key={email}
                button
                onClick={handleToggle(email)}
                style={
                  i % 2
                    ? { backgroundColor: 'white' }
                    : { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                }
              >
                <ListItemText id={email} primary={name} secondary={email} />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    tabIndex="-1"
                    onClick={handleToggle(email)}
                    checked={checked.indexOf(email) !== -1}
                    inputProps={{ 'aria-labelledby': name }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            // disabled={isSubmitting}
            onClick={handleSubmit}
            size="large"
          >
            Continue
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

Step2.propTypes = {
  handleNext: PropTypes.func.isRequired,
  setSelectedCard: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
  selectedCard: PropTypes.shape({
    recipients: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedCard: (payload) =>
    dispatch(cards.actions.setSelectedCard(payload)),
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
});

const mapStateToProps = (state) => ({
  selectedCard: cards.selectors.selectSelectedCard(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
