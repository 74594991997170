import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell as MuiTableCell,
  Checkbox,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import i18n from 'i18next';
import Button from '@material-ui/core/Button';
import { Skeleton } from '@material-ui/lab';
import { alert, businesses, cards, checkout, enterprise } from '../../../state';
import PoweredBySquare from '../../../images/poweredBySquare.svg';
import PoweredByClover from '../../../images/poweredByClover.png';
import BulkCheckoutSquare from './BulkCheckoutSquare';
import BulkCheckoutClover from './BulkCheckoutClover';

const useStyles = makeStyles((theme) => ({
  squareLogo: {
    height: '20px',
  },
  stripeLogo: {
    maxHeight: '30px',
  },
  cloverLogo: {
    maxHeight: '20px',
  },
  item1: {
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  item2: {
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
}));
const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '0 0 10px 0',
  },
})(MuiTableCell);

const BulkOrderBreakdown = ({ data }) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const giftCardConfig = useSelector(businesses.selectors.selectBusiness);
  const isLoading = useSelector(checkout.selectors.selectIsLoading);

  const [useBalance, setUseBalance] = useState(false);
  const [sending, setSending] = useState(false);
  // const cardDetails = useSelector(cards.selectors.selectSelectedCard);
  const [totalAmounts, setTotalAmounts] = useState({
    totalAmount: 0,
    fees: 0,
    giveBackAmount: 0,
    customerPaysAmount: 0,
    availableCredit: 0,
  });
  const dispatch = useDispatch();
  const formData = useSelector(checkout.selectors.selectFormData);
  const setFormData = (payload) =>
    dispatch(checkout.actions.setFormData(payload));
  const getTotalAmount = async () => {
    if (giftCardConfig && data) {
      const res = await dispatch(
        cards.actions.calculateBulkIssue({
          giftCardConfigId: giftCardConfig?.id,
          amount: data.reduce(
            (a, b) =>
              Number(a) +
              Number((b.Amount ? b.Amount.toString() : '0').match(/(\d+)/)[1]),
            0
          ),
        })
      );
      if (res.payload) {
        await setTotalAmounts({
          ...res.payload,
          balanceDue:
            res.payload.availableCredit > res.payload.customerPaysAmount
              ? res.payload.availableCredit - res.payload.customerPaysAmount
              : 0,
        });
        await setFormData({
          ...formData,
          amount:
            res.payload.availableCredit > res.payload.customerPaysAmount
              ? 0
              : res.payload.customerPaysAmount - res.payload.availableCredit,
          totalAmount: res.payload.totalAmount,
        });
      }
      setTimeout(() => setUseBalance(true));
    }
  };
  const [error, setError] = useState('');

  useEffect(() => {
    getTotalAmount();
  }, [data, giftCardConfig]);

  useEffect(() => {
    if (totalAmounts.customerPaysAmount || totalAmounts.amount) {
      let payAmount;
      if (!useBalance) {
        setTotalAmounts({
          ...totalAmounts,
          balanceDue: totalAmounts.availableCredit,
        });
        payAmount = totalAmounts.customerPaysAmount;
        setFormData({
          ...formData,
          amount: payAmount,
        });
      } else {
        const balanceDue =
          totalAmounts.availableCredit - totalAmounts.customerPaysAmount >= 0
            ? totalAmounts.availableCredit - totalAmounts.customerPaysAmount
            : 0;
        setTotalAmounts({
          ...totalAmounts,
          balanceDue,
        });
        payAmount =
          totalAmounts.availableCredit > totalAmounts.customerPaysAmount
            ? 0
            : totalAmounts.customerPaysAmount - totalAmounts.availableCredit;

        setFormData({
          ...formData,
          amount: payAmount,
        });
      }
      if (payAmount > 10000) {
        setError(
          `Total Credit Checkout Amount Limit Is ${t('cardValue', {
            amount: 10000,
            formatParams: { amount: { currency: giftCardConfig.currency } },
          })}`
        );
      } else {
        setError('');
      }
    }
  }, [useBalance]);

  const changeUseBalance = () => {
    setUseBalance(!useBalance);
  };
  const resetFormData = () => dispatch(checkout.actions.resetFormData());
  const bulkCheckout = (payload) =>
    dispatch(checkout.actions.bulkCheckout(payload));
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const enterpriseInfo = useSelector(enterprise.selectors.selectInfo);

  const onPaymentSuccess = async (nonce, verificationToken) => {
    setSending(true);
    const response = await bulkCheckout({
      ...formData,
      enterpriseId: enterpriseInfo.id,
      giftCardConfigId: formData.giftCardConfigId ?? formData.id,
      amount: formData.totalAmount,
      customerPaysAmount: formData.amount,
      nonce,
      verificationToken,
      culture: i18n.language,
      useBalance,
    });

    if (response.error) {
      openAlert({
        message:
          response.error?.message || t('weCannotProcessPaymentsPleaseTryLater'),
        severity: 'error',
      });
    } else if (response.payload) {
      openAlert({
        message: t('bulkIssueSuccess'),
        severity: 'success',
      });
      resetFormData();
      dispatch(enterprise.actions.getInfo());
      dispatch(alert.actions.setModal(false));
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Card raised>
          <CardContent>
            <Typography gutterBottom variant="body2">
              <b>{t('changeBreakdown')}</b>
            </Typography>
            <TableContainer>
              <Table aria-label="Charge breakdown">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {t('giftCardValue')}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {t('cardValue', {
                        amount: totalAmounts?.totalAmount,
                        formatParams: {
                          amount: { currency: giftCardConfig.currency },
                        },
                      })}
                    </TableCell>
                    <TableCell />
                  </TableRow>

                  {totalAmounts.giveBackAmount > 0 && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body2" style={{ color: 'green' }}>
                          {t('youSave')}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2" style={{ color: 'green' }}>
                          {t('cardValue', {
                            amount: totalAmounts.giveBackAmount.toFixed(2),
                            formatParams: {
                              amount: { currency: giftCardConfig.currency },
                            },
                          })}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {totalAmounts.fees > 0 && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body2" style={{ color: 'red' }}>
                          {t('fees', { fees: giftCardConfig.fees })}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2" style={{ color: 'red' }}>
                          {t('cardValue', {
                            amount: totalAmounts.fees,
                            formatParams: {
                              amount: { currency: giftCardConfig.currency },
                            },
                          })}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2">
                        {t('totalAmountDue')}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {t('cardValue', {
                        amount:
                          totalAmounts.customerPaysAmount ||
                          totalAmounts.totalAmount,
                        formatParams: {
                          amount: { currency: giftCardConfig.currency },
                        },
                      })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2">
                        {t('availableCredit')}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {t('cardValue', {
                        amount: totalAmounts.availableCredit,
                        formatParams: {
                          amount: { currency: giftCardConfig.currency },
                        },
                      })}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={useBalance === true}
                        onClick={() => changeUseBalance()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2">
                        <b>{t('balanceDue')}</b>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <b>
                        {t('cardValue', {
                          amount: formData.amount || 0,
                          formatParams: {
                            amount: { currency: giftCardConfig.currency },
                          },
                        })}
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2">
                        {t('creditBalance')}
                        <br />
                        {`(${t('afterPayment')})`}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {t('cardValue', {
                        amount: totalAmounts.balanceDue || 0,
                        formatParams: {
                          amount: { currency: giftCardConfig.currency },
                        },
                      })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {error && (
              <Box mt={2}>
                <Typography variant="body2" style={{ color: 'orangered' }}>
                  {error}
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>

      {(!useBalance ||
        totalAmounts.availableCredit < totalAmounts.customerPaysAmount) &&
      formData.amount ? (
        <>
          {giftCardConfig.partner === 'Square' && (
            <Box mt={2}>
              {error ? (
                <Box mt={4}>
                  <Button
                    color="primary"
                    fullWidth
                    variant="contained"
                    disabled
                  >
                    Pay
                  </Button>
                </Box>
              ) : (
                <BulkCheckoutSquare onPaymentSuccess={onPaymentSuccess} />
              )}
              <Box py={2} align="center">
                <img
                  src={PoweredBySquare}
                  alt="Powered By Square"
                  className={classes.squareLogo}
                />
              </Box>
            </Box>
          )}
          {giftCardConfig.partner === 'Clover' && (
            <Box mt={4}>
              {error ? (
                <Box mt={4}>
                  <Button
                    color="primary"
                    fullWidth
                    variant="contained"
                    alignItems="center"
                    disabled
                  >
                    Pay
                  </Button>
                </Box>
              ) : (
                <BulkCheckoutClover onPaymentSuccess={onPaymentSuccess} />
              )}
              <Box py={2} align="center">
                <img
                  src={PoweredByClover}
                  alt="Powered By Clover"
                  className={classes.cloverLogo}
                />
              </Box>
            </Box>
          )}
        </>
      ) : (
        <Box mt={3} textAlign="center">
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={40} />
          ) : (
            <Button
              onClick={() => onPaymentSuccess(null, null)}
              color="primary"
              fullWidth
              variant="contained"
              alignItems="center"
              disabled={!!error}
            >
              Pay
            </Button>
          )}
        </Box>
      )}
      <Dialog
        open={sending}
        onClose={() => {
          setSending(false);
          dispatch(alert.actions.setModal(false));
        }}
        maxWidth="md"
        fullWidth
        scroll="paper"
        aria-labelledby="Bulk Issue"
        aria-describedby="Bulk Issue"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogContent dividers>
          <DialogContentText tabIndex={-1} component="div">
            <Typography variant="h6" gutterBottom color="textSecondary">
              Your order for {formData?.data?.length} gift cards has been
              submitted. We will start generating and sending the gift cards
              immediately. We will send you a confirmation email once all the
              gift cards are sent.
              <br /> Thank you for your order.
            </Typography>
            <Box textAlign="center">
              <Button
                onClick={() => {
                  setSending(false);
                  dispatch(alert.actions.setModal(false));
                }}
                color="primary"
                variant="contained"
              >
                Ok
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

BulkOrderBreakdown.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  business: businesses.selectors.selectBusiness(state),
  formData: checkout.selectors.selectFormData(state),
  cardDetails: businesses.selectors.selectCardDetails(state),
});

export default connect(mapStateToProps)(BulkOrderBreakdown);
