/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Skeleton, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';
import { getOr, isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { Formik, Form, Field } from 'formik';
import { TextField, Checkbox } from 'formik-material-ui';
import { auth, businesses, cards, checkout } from '../../../state';
import { CustomImgCard, CustomInputField } from '../../custom';
import { UserInfoSchema } from './addFundValidation';
import { handleCloverSubmit, handleSquareSubmit } from './PosSubmits';

const useStyles = makeStyles((theme) => ({
  fsBackground: {
    background: `#e4f9fc`,
    minHeight: '170px',
    maxHeight: '170px',
    width: '100%',
    position: 'absolute',
    left: 0,
    zIndex: -1,
  },
  checkBalance: {
    position: 'absolute',
    right: '5.7%',
    top: '50%',
    width: '250px',
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    transform: 'translateY(-50%)',
    '&:hover': {
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      top: 'auto',
      right: 0,
      fontSize: '13px',
    },
  },
  checkBalanceWrapper: {
    position: 'absolute',
    width: '100%',
    top: '65px',
    left: 0,
    height: '170px',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      top: 'auto',
      height: 'auto',
      position: 'relative',
      paddingTop: '25px',
    },
  },
  btn: {
    borderColor: theme.palette.light,
    flexGrow: 1,
  },
  skeleton: {
    marginRight: '5px',
    height: '70px',
    width: '20%',
  },
}));

const CardAddFundsSection = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  // const [open, setOpen] = React.useState(false);
  // const [expand, setExpand] = React.useState(false);
  const business = useSelector(businesses.selectors.selectBusiness);
  const cardDetails = useSelector(cards.selectors.selectSelectedCard);
  const user = useSelector(auth.selectors.selectUser);
  const { balance, currency } = cardDetails;
  const dispatch = useDispatch();
  const setFormData = (payload) =>
    dispatch(checkout.actions.setFormData(payload));
  const getTotals = (payload) => dispatch(checkout.actions.getTotals(payload));
  const setStep = (step) => dispatch(checkout.actions.setStep(step));
  const isLoading = useSelector(businesses.selectors.selectIsLoading);

  const [customAmount, setCustomAmount] = React.useState(false);
  const cardValues = getOr([], 'amounts', business);
  const minCardAmount = cardValues[0];
  const {
    // id,
    allowCustomAmount,
    minAmount,
    maxAmount,
    minFeeCoverAmount,
    partner,
    country,
    discounts,
    customAmountDiscount,
    name,
    imageUrl,
  } = business;
  // const dispatch = useDispatch();
  // const setFormData = (payload) =>
  //   dispatch(checkout.actions.setFormData(payload));
  //
  // const { name, imageUrl, terms, description } = business;
  //
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  //
  // const handleClose = () => {
  //   setOpen(false);
  // };
  //
  // const onClick = () => {
  //   setExpand(!expand);
  // };

  const handleBack = () => {};

  return (
    <>
      <div className={classes.fsBackground} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Box pt={2} display="block" align="center">
              <Typography variant="h5" color="primary">
                {isLoading ? <Skeleton width="190px" /> : business.name}
              </Typography>
            </Box>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Box py={2}>
                <CustomImgCard
                  isLoading={isLoading}
                  name={name}
                  width="270px"
                  imageUrl={imageUrl}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <Formik
            enableReinitialize
            validationSchema={() => UserInfoSchema(maxAmount, minAmount)}
            initialValues={{
              businessId: cardDetails.businessId || '',
              id: cardDetails.id || business.id,
              giftCardConfigId: business.giftCardConfigId || '',
              giftCardImageId: business.giftCardImageId || '',
              giftCardTermId: business.giftCardTermId || '',
              occasionId: business.occasionId || '',
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              promoCode: '',
              sameAsRecepient: false,
              amount: minCardAmount,
              currency: cardDetails.currency || 'USD',
              country: country || 'US',
              code: cardDetails.code || '',
              balance: cardDetails.balance || '',
              giftFirstName:
                cardDetails.giftFirstName || cardDetails.firstName || '',
              giftLastName:
                cardDetails.giftLastName || cardDetails.lastName || '',
              giftEmail: cardDetails.giftEmail || cardDetails.email || '',
              giftMessage: '',
              customAmount: false,
              fees: business.fees,
              feesHelperText: business.feesHelperText,
              isFeeCovered: false,
            }}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);
              // eslint-disable-next-line no-param-reassign
              values.isGift = values.giftEmail?.trim() !== values.email?.trim();
              if (partner === 'Square') {
                await handleSquareSubmit(values, getTotals, setFormData);
              }
              if (partner === 'Clover') {
                await handleCloverSubmit(values, getTotals, setFormData);
              }
              setStep(2);
              actions.setSubmitting(false);
            }}
          >
            {({ setFieldValue, values, isSubmitting, handleSubmit }) => {
              const { amount, sameAsRecepient } = values;
              const handleCardValueClick = (cardValue) => {
                if (cardValue < minFeeCoverAmount) {
                  setFieldValue('isFeeCovered', true);
                }
                setFieldValue('amount', cardValue);
                setFieldValue('customAmount', false);
                setCustomAmount(false);
              };

              const handleCustomAmountChange = (e) => {
                if (amount < minAmount || amount < minFeeCoverAmount) {
                  setFieldValue('isFeeCovered', true);
                }
                setFieldValue('amount', e.target.value);
              };

              useEffect(() => {
                setFieldValue(
                  'firstName',
                  sameAsRecepient ? values.giftFirstName : values.firstName
                );
                setFieldValue(
                  'lastName',
                  sameAsRecepient ? values.giftLastName : values.lastName
                );
                setFieldValue(
                  'email',
                  sameAsRecepient ? values.giftEmail : values.email
                );
              }, [sameAsRecepient]);
              return (
                <Form>
                  <Box
                    pt={4}
                    pb={2}
                    sx={{
                      padding: '10px 15px',
                      border: `1px solid #808080`,
                      borderRadius: '5px',
                      opacity: 0.5,
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={12} md={7}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          label={t('giftCardNumber')}
                          name="code"
                          size="small"
                          variant="outlined"
                          required
                          fullWidth
                          disabled
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          label={t('cardBalance')}
                          name="balance"
                          size="small"
                          value=""
                          startAdornment={t('cardValue', {
                            amount: balance,
                            formatParams: {
                              amount: { currency },
                            },
                          })}
                          variant="outlined"
                          fullWidth
                          disabled
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </Grid>

                    <Box pt={2} pb={2}>
                      <Typography variant="body2">
                        {t('reloadedGiftCardWillBeSentToOriginalRecipient')}
                      </Typography>
                    </Box>
                    <Box pb={2}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid item xs={12} md={6}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            capitalize
                            fullWidth
                            required
                            label={t('autoRecipientFirstName')}
                            name="giftFirstName"
                            variant="outlined"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            capitalize
                            fullWidth
                            required
                            label={t('autoRecipientLastName')}
                            name="giftLastName"
                            variant="outlined"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box pb={2}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        trim
                        fullWidth
                        required
                        label={t('autoRecipientEmail')}
                        name="giftEmail"
                        variant="outlined"
                        disabled
                      />
                    </Box>
                  </Box>
                  <Box pt={2} pb={1}>
                    <Typography variant="body2">
                      {t('giftCardAmount')}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexWrap="nowrap"
                    pb={2}
                    width="100%"
                    justifyContent="center"
                  >
                    {isLoading ? (
                      <>
                        <Skeleton className={classes.skeleton} />
                        <Skeleton className={classes.skeleton} />
                        <Skeleton className={classes.skeleton} />
                        <Skeleton className={classes.skeleton} />
                        <Skeleton height="70px" width="20%" />
                      </>
                    ) : (
                      <ToggleButtonGroup
                        id="amount"
                        aria-label="Select card value"
                        style={{ width: '100%' }}
                      >
                        {cardValues.map(
                          (cardValue, i) =>
                            cardValue && (
                              <ToggleButton
                                variant="contained"
                                color="primary"
                                selected={cardValue === amount}
                                onClick={() => handleCardValueClick(cardValue)}
                                key={cardValue}
                                value={cardValue}
                                aria-label={cardValue}
                                className={classes.btn}
                              >
                                <Box display="flex" flexDirection="column">
                                  {!isEmpty(discounts) &&
                                  discounts[i] < cardValue ? (
                                    <>
                                      <Box>
                                        {t('cardValue', {
                                          amount: discounts[i].toFixed(2),
                                          formatParams: {
                                            amount: { currency },
                                          },
                                        })}
                                      </Box>
                                      <Typography
                                        variant="caption"
                                        style={{
                                          textTransform: 'none',
                                          lineHeight: '1.25rem',
                                          display: 'inline-block',
                                          paddingTop: '0.25rem',
                                          paddingBottom: '0.25rem',
                                        }}
                                      >
                                        {t('actualValue')}
                                        <br />
                                        {t('cardValue', {
                                          amount: cardValue.toFixed(2),
                                          formatParams: {
                                            amount: {
                                              currency: business.currency,
                                            },
                                          },
                                        })}
                                        <br />
                                        {`(${(
                                          ((cardValue - discounts[i]) /
                                            cardValue) *
                                          100
                                        ).toFixed(1)}% ${t('off')})`}
                                      </Typography>
                                    </>
                                  ) : (
                                    t('cardValue', {
                                      amount: cardValue.toFixed(2),
                                      formatParams: {
                                        amount: {
                                          currency: business.currency,
                                        },
                                      },
                                    })
                                  )}
                                </Box>
                              </ToggleButton>
                            )
                        )}
                        {allowCustomAmount && (
                          <ToggleButton
                            className={classes.btn}
                            variant="contained"
                            color="primary"
                            size="small"
                            selected={customAmount === true}
                            onClick={() => {
                              setFieldValue('amount', '');
                              setFieldValue('customAmount', true);
                              setCustomAmount(true);
                            }}
                            key="custom"
                            value="custom"
                            aria-label="custom"
                          >
                            <Box display="flex" flexDirection="column">
                              <Box>{t('custom')}</Box>
                              {!!customAmountDiscount && (
                                <Typography variant="caption">
                                  {`(${(customAmountDiscount * 100).toFixed(
                                    1
                                  )}% off)`}
                                </Typography>
                              )}
                            </Box>
                          </ToggleButton>
                        )}
                      </ToggleButtonGroup>
                    )}
                  </Box>

                  {allowCustomAmount && customAmount && (
                    <Box pb={2}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        name="amount"
                        label={t('enterCustomAmount')}
                        variant="outlined"
                        trimLeadingZeros
                        onChange={handleCustomAmountChange}
                        startAdornment={cardDetails.currency}
                      />
                    </Box>
                  )}
                  <h3>{t('YourInformation')}</h3>
                  <Box pb={2} display="flex" alignItems="center">
                    <Box mr="3">
                      <Field
                        type="checkbox"
                        component={Checkbox}
                        color="primary"
                        name="sameAsRecepient"
                      />
                    </Box>
                    <Typography variant="caption">
                      {t('sameAsRecepient')}
                    </Typography>
                  </Box>
                  <Box pb={2}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={12} md={6}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          capitalize
                          fullWidth
                          required
                          label={t('yourFirstName')}
                          name="firstName"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          capitalize
                          fullWidth
                          required
                          label={t('yourLastName')}
                          name="lastName"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box pb={2}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      trim
                      fullWidth
                      required
                      label={t('yourEmail')}
                      name="email"
                      variant="outlined"
                    />
                  </Box>

                  <Box pb={10}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={handleBack}
                          size="large"
                        >
                          {t('back')}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={handleSubmit}
                          size="large"
                        >
                          {isSubmitting ? (
                            <CircularProgress size={24} />
                          ) : (
                            t('continue')
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default CardAddFundsSection;
