import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { DownloadCSV, TransactionTable } from '../../custom';
import { enterprise, businesses } from '../../../state';
import searchIcon from '../../../images/search.png';
import DownloadIcon from '../../../images/download.png';

const Redemption = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, total } = useSelector(enterprise.selectors.selectRedemptions);
  const isLoading = useSelector(enterprise.selectors.selectIsLoading);
  const [params, setParams] = useState({ page: 1, limit: 10 });
  const [csv, setCsv] = useState([]);
  const [value] = useDebounce(params, 700);

  const business = useSelector(businesses.selectors.selectBusiness);

  const getRedemptions = (_params = null) => {
    dispatch(enterprise.actions.getHistoryRedemptions(_params || params));
  };

  React.useEffect(() => {
    getRedemptions();
  }, []);

  React.useEffect(() => {
    if (value?.keyword === '' || value?.keyword?.length > 2) {
      getRedemptions();
    }
  }, [value]);

  const handleChangePage = async (event, page) => {
    const p = { ...params, page: page + 1 };
    setParams(p);
    getRedemptions(p);
  };
  const handleChangeRowsPerPage = async (event) => {
    const p = { ...params, limit: event.target.value, page: 1 };
    setParams(p);
    getRedemptions(p);
  };

  const cols = [
    t('redemption.giftCard'),
    t('redemption.redemptionDate'),
    t('redemption.amountRedeemed'),
  ];
  const rowKeys = ['code', 'createdAt', 'amount'];

  const mods = {
    createdAt: (val) => moment(`${val}Z`).format('MMMM DD YYYY, HH:mm:ss'),
    deliverAt: (val) => (val ? moment(`${val}Z`).format('MMMM DD YYYY') : ''),
    amount: (val) =>
      `${t('cardValue', {
        amount: val.toFixed(2),
        formatParams: {
          amount: { currency: business.currency || 'USD' },
        },
      })}`,
  };

  const refLink = React.useRef();

  const handleDownload = () => {
    if (data && data.length > 0) {
      const preparedData = data.map((item) => ({
        'Gift Card #': item.code,
        'Redemption Date': moment(`${item.createdAt}Z`).format(
          'MMMM DD YYYY, HH:mm:ss'
        ),
        'Amount Redeemed': item.amount,
      }));
      setCsv(preparedData);
      setTimeout(() => {
        refLink.current.link.click();
      }, 1000);
    }
  };

  const searchBox = () => (
    <Grid item>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item style={{ minWidth: 350 }}>
          <TextField
            placeholder={t('redemption.placeholderSearch')}
            value={params.keyword}
            fullWidth
            type="string"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            name="keyword"
            onChange={(val) => {
              setParams({ ...params, keyword: val.target.value });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => getRedemptions()}
                    style={{ marginRight: -14 }}
                  >
                    <img
                      src={searchIcon}
                      alt={t('search')}
                      style={{
                        width: 25,
                        height: 25,
                        paddingTop: 2,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item style={{ marginBottom: 2, padding: 0 }}>
          <DownloadCSV
            handleDownload={handleDownload}
            refLink={refLink}
            data={csv}
            filename="redemption-list"
          >
            <Tooltip
              title={t('membership.membershipList.downloadCSV')}
              placement="top"
              arrow
            >
              <Button onClick={handleDownload}>
                <img
                  src={DownloadIcon}
                  alt={t('membership.membershipList.downloadCSV')}
                  style={{ width: 30, cursor: 'pointer' }}
                />
              </Button>
            </Tooltip>
          </DownloadCSV>
        </Grid>
      </Grid>
    </Grid>
  );

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  return (
    <Grid container spacing={4}>
      <Grid container item xs={12} md={10}>
        <TransactionTable
          cols={cols}
          rowKeys={rowKeys}
          searchBox={searchBox}
          data={data}
          mods={mods}
          isLoading={isLoading}
          noDataMsg={t('noData')}
          pagination={pagination}
        />
      </Grid>
    </Grid>
  );
};

export default Redemption;
