/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  MenuItem,
  Divider,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Field, Form, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import { navigate } from 'gatsby';
import { businesses, checkout } from '../../../state';
import { CustomImgCard } from '../../custom';

const useStyles = makeStyles((theme) => ({
  formControl: {
    border: '1px solid #d0d7de',
    marginLeft: 20,
    // boxShadow: '1px 1px 1px 1px #848484',
    // borderRadius: 10,
  },
  option: {
    padding: 5,
    marginLeft: 24,
  },
  fsBackground: {
    background: `#e4f9fc`,
    minHeight: '80px',
    maxHeight: '80px',
    width: '100%',
    position: 'absolute',
    left: 0,
    zIndex: -1,
  },
  checkBalance: {
    position: 'absolute',
    right: '5.7%',
    top: '50%',
    width: '250px',
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    transform: 'translateY(-50%)',
    '&:hover': {
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      top: 'auto',
      right: 0,
      fontSize: '13px',
    },
  },
  checkBalanceWrapper: {
    position: 'absolute',
    width: '100%',
    top: '65px',
    left: 0,
    height: '170px',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      top: 'auto',
      height: 'auto',
      position: 'relative',
      paddingTop: '25px',
    },
  },
}));

const SelectGiftCardImage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const business = useSelector(businesses.selectors.selectBusiness);
  const isLoading = useSelector(businesses.selectors.selectIsLoading);
  const { name, occasions } = business;
  const [filteredOccasions, setFilteredOccasions] = useState(occasions);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const updateBusiness = (payload) =>
    dispatch(businesses.actions.getGiftCardConfig(payload?.id));
  const setFormData = (payload) =>
    dispatch(checkout.actions.setFormData(payload));
  const setStep = (payload) => dispatch(checkout.actions.setStep(payload));
  useEffect(() => {
    if (!business.id) {
      navigate('/');
    }
    if (occasions) {
      setFilteredOccasions(
        occasions
          .map((o) => ({
            ...o,
            images: o.images.filter((i) => i.status === 0),
          }))
          .filter((o) => o.status === 0 && o.images.length > 0)
      );
      if (
        filteredOccasions &&
        filteredOccasions.length === 1 &&
        filteredOccasions[0].images.length === 1
      ) {
        // navigate(`/card/${id}`);
      }
    }
  }, [business]);

  return (
    <>
      <div className={classes.fsBackground} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: '60px', marginBottom: '50px' }}
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box pt={2} display="block" align="center">
            <Typography variant="h5" color="primary">
              {isLoading ? <Skeleton width="190px" /> : name}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={12}>
          <Formik
            enableReinitialize
            validationSchema={Yup.object({
              occasionId: Yup.string().required(t('validation.required')),
              giftCardImageId: Yup.string().required(t('validation.required')),
            })}
            initialValues={{
              ...business,
              occasion: {},
            }}
            onSubmit={async (values) => {
              console.log(values);
              await updateBusiness(values);
              await setFormData(values);
              if (
                values.giftCardImageId &&
                values.occasionId &&
                values.imageUrl
              ) {
                setStep(0);
              }
            }}
          >
            {({ setFieldValue, values, isSubmitting, handleSubmit }) => {
              return (
                <Form>
                  <Grid
                    container
                    // alignItems="center"
                    justifyContent="center"
                    style={{ marginBottom: 20 }}
                  >
                    <Typography>GIFT CARD BY OCCASION</Typography>

                    <Field
                      component={FormikTextField}
                      select
                      multiple
                      native
                      name="occasion"
                      variant="outlined"
                      style={{ minWidth: 200, marginLeft: 20 }}
                      InputLabelProps={{ shrink: true }}
                    >
                      {filteredOccasions &&
                        filteredOccasions.map((occasion) => (
                          <MenuItem
                            key={occasion.id}
                            value={occasion}
                            onClick={() =>
                              setFieldValue('occasionId', occasion.id)
                            }
                          >
                            {occasion.name}
                          </MenuItem>
                        ))}
                    </Field>
                  </Grid>

                  {values.occasion &&
                  values.occasion.images &&
                  values.occasion.images.length ? (
                    <>
                      <Typography style={{ marginLeft: 4 }}>
                        Select your gift card:
                      </Typography>
                      <Divider />
                      <Grid
                        container
                        direction="row"
                        justifyContent={xs ? 'center' : 'flex-start'}
                        alignItems="center"
                        spacing={4}
                      >
                        {values.occasion.images.map((image) => (
                          <Grid
                            key={image.id}
                            item
                            onClick={() => {
                              setFieldValue('giftCardImageId', image.id);
                              setFieldValue('imageUrl', image.url);
                            }}
                          >
                            <Box
                              mt={2}
                              style={{ boxSizing: 'border-box' }}
                              border={
                                image.id === values.giftCardImageId ? 1 : 0
                              }
                            >
                              <CustomImgCard
                                isLoading={isLoading}
                                name={name}
                                width="215px"
                                imageUrl={image.url}
                              />
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                      <Box mt={2} mb={3}>
                        <Grid container justifyContent="center">
                          <Grid item xs={12} md={6}>
                            <Button
                              fullWidth
                              type="submit"
                              variant="contained"
                              color="primary"
                              onClick={handleSubmit}
                              size="large"
                            >
                              {isSubmitting ? (
                                <CircularProgress size={24} />
                              ) : (
                                t('continue')
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  ) : (
                    values.occasion &&
                    values.occasion.images &&
                    !values.occasion.images.length && (
                      <>
                        <Typography variant="h5" style={{ marginLeft: 4 }}>
                          There is no available gift card images for this
                          occasion
                        </Typography>
                      </>
                    )
                  )}
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectGiftCardImage;
