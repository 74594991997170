import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { businesses, checkout, cards } from '../../../state';

import CardInfoSection from './CardInfoSection';
import CardAddFundsSection from './CardAddFundsSection';
import SEO from '../../seo';
import { Content } from '../../layouts';
import Checkout from '../Checkout/Checkout';
import SelectGiftCardImage from './SelectGiftCardImage';
import TransactionDetails from '../Checkout/TransactionDetails';

const CardDetails = () => {
  const { i18n } = useTranslation();
  const business = useSelector(businesses.selectors.selectBusiness);
  const cardDetails = useSelector(cards.selectors.selectSelectedCard);

  React.useEffect(() => {
    if (business && business.culture) {
      i18n.changeLanguage(business.culture);
    }
  }, [business]);

  const { name, isWhitelabel } = business;
  const checkoutStep = useSelector(checkout.selectors.selectStep);
  return (
    <>
      <SEO title={`${name}`} />
      <Content
        style={{ minHeight: !isWhitelabel && 'calc(100vh - 64px - 196px)' }}
        md={12}
      >
        {checkoutStep === 0 &&
          (cardDetails?.code ? <CardAddFundsSection /> : <CardInfoSection />)}
        {checkoutStep === 1 && <SelectGiftCardImage />}
        {checkoutStep === 2 && <Checkout />}
        {checkoutStep === 3 && <TransactionDetails />}
      </Content>
    </>
  );
};

export default CardDetails;
