import React from 'react';
import {
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  withStyles,
  Button,
  TableCell as MuiTableCell,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomImgCard } from '../../custom';
import defaultCardImage from '../../../images/defaultGiftCard.jpg';
import { businesses, cards, checkout } from '../../../state';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '0 0 10px 0',
  },
})(MuiTableCell);

const TransactionDetails = () => {
  const { t } = useTranslation();
  const formData = useSelector(checkout.selectors.selectFormData);
  const orderNumber = useSelector(checkout.selectors.selectOrderNumber);
  const business = useSelector(businesses.selectors.selectBusiness);
  const cardDetails = useSelector(cards.selectors.selectSelectedCard);
  const dispatch = useDispatch();
  const setStep = (payload) => dispatch(checkout.actions.setStep(payload));
  const {
    totalAmount,
    cardAmount,
    organizationName,
    transactionFee,
    isFeeCovered,
    giveBackAmount,
    customerPaysAmount,
    fees,
  } = formData;
  const savedAmount =
    Number(totalAmount) + Number(fees) - Number(customerPaysAmount);
  const { name, imageUrl, currency, fees: configFees } = business;
  const handleBuyMoreCards = () => {
    setStep(0);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" component="h2">
          <b>
            {cardDetails.code && `${t('addFunds')}: `}
            {t('transactionSuccessful')}
          </b>
        </Typography>
        <Typography gutterBottom variant="h6" component="h2">
          {t('orderNumber')} <b>{`${orderNumber}`} </b>
        </Typography>
        <Typography gutterBottom variant="body2" component="p">
          {t('receiveEmail')}
        </Typography>
        <Typography gutterBottom variant="body2" component="p">
          {t('giftCardPurchased')}
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={5}>
            <CustomImgCard
              name={name || 'default image'}
              imageUrl={imageUrl || defaultCardImage}
              elevation={3}
              variant="elevation"
              width="100%"
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Box width="100%" pt={1}>
              <TableContainer>
                <Table aria-label="Charge breakdown">
                  <TableBody>
                    {cardDetails.balance && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            component="p"
                          >
                            {t('newGiftCardBalance')}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            display="block"
                            align="right"
                            variant="body2"
                          >
                            <b>
                              {t('cardValue', {
                                amount:
                                  cardDetails.balance + Number(cardAmount),
                                formatParams: {
                                  amount: { currency },
                                },
                              })}
                            </b>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {cardDetails.balance
                            ? t('addedBalance')
                            : t('giftCardValue')}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {t('cardValue', {
                          amount: cardAmount,
                          formatParams: {
                            amount: { currency },
                          },
                        })}
                      </TableCell>
                    </TableRow>
                    {isFeeCovered && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {t('creditCardProcessingFee')}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {t('cardValue', {
                            amount: transactionFee,
                            formatParams: {
                              amount: { currency },
                            },
                          })}
                        </TableCell>
                      </TableRow>
                    )}
                    {savedAmount > 0 && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="body2"
                            style={{ color: 'green' }}
                          >
                            {t('youSaved')}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="body2"
                            style={{ color: 'green' }}
                          >
                            {t('cardValue', {
                              amount: savedAmount,
                              formatParams: {
                                amount: { currency },
                              },
                            })}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {fees > 0 && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {t('fees', { fees: configFees })}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {t('cardValue', {
                            amount: fees,
                            formatParams: {
                              amount: { currency },
                            },
                          })}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body2">
                          <b>{t('amountCharged')}</b>
                        </Typography>{' '}
                      </TableCell>
                      <TableCell align="right">
                        <b>
                          {t('cardValue', {
                            amount: customerPaysAmount || totalAmount,
                            formatParams: {
                              amount: { currency },
                            },
                          })}
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {organizationName && (
              <Box py={2}>
                <Typography
                  gutterBottom
                  variant="body2"
                  style={{ color: '#848418' }}
                  component="p"
                >
                  <b>
                    {t('yourSocialImpact')} ={' '}
                    {t('cardValue', {
                      amount: giveBackAmount,
                      formatParams: {
                        amount: { currency },
                      },
                    })}{' '}
                    {t('willBeGivenTo')} {`${organizationName}`}
                  </b>
                </Typography>
              </Box>
            )}

            <Box pb={2} align="center">
              <Button
                onClick={handleBuyMoreCards}
                variant="contained"
                color="primary"
                fullWidth
                size="large"
              >
                {t('buyMoreCard')}
              </Button>
            </Box>
          </Grid>
          <Box display={{ xs: 'none', md: 'block' }}>
            <Grid item xs={12} sm={3} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TransactionDetails;
