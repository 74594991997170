import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Divider } from '@material-ui/core';

import TypeFilters from './TypeFilters';
import CategoryFilters from './CategoriesFilters';
import CardList from './CardList';
import { types, cards, categories } from '../../../state';
import findDefaultType from '../../../utils/findDefaultType';

const CardSection = ({
  handleNext,
  flipCard,
  fetchTypes,
  getCategories,
  setSelectedTypeFilter,
  setSelectedCategoryFilter,
  getCards,
  setCards,
}) => {
  React.useEffect(() => {
    setCards([{ id: '1' }]);
    (async () => {
      const { payload } = await fetchTypes();
      const defaultType = findDefaultType(payload);
      await getCategories(defaultType);
      await setSelectedCategoryFilter('');

      return defaultType;
    })().then((defaultType) => {
      setSelectedTypeFilter(defaultType);
      getCards(defaultType);
    });
  }, []);

  return (
    <>
      <Box mb={3}>
        {/* <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            > */}
        {/* <Grid item xs={8}> */}
        <Box display="flex" flexWrap="nowrap">
          <TypeFilters />
        </Box>
        {/* </Grid>
              <Grid item xs={4}>
                <Box display="block" flexWrap="nowrap" align="right">
                  <CardFilters />
                </Box>
              </Grid>
            </Grid> */}
        <Divider variant="fullWidth" />
        <Box display="flex" flexWrap="wrap">
          <CategoryFilters />
        </Box>
      </Box>
      <CardList handleNext={handleNext} flipCard={flipCard} />
    </>
  );
};

CardSection.propTypes = {
  handleNext: PropTypes.func.isRequired,
  flipCard: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  setSelectedTypeFilter: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  fetchTypes: PropTypes.func.isRequired,
  setSelectedCategoryFilter: PropTypes.func.isRequired,
  getCards: PropTypes.func.isRequired,
  setCards: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getCards: () => dispatch(cards.actions.getCards()),
  setCards: (payload) => dispatch(cards.actions.setCards(payload)),
  fetchTypes: () => dispatch(types.actions.fetchTypes()),
  getCategories: (typeId) => dispatch(categories.actions.getCategories(typeId)),
  // setters
  setSelectedTypeFilter: (typeId) =>
    dispatch(types.actions.setSelectedTypeFilter(typeId)),
  setSelectedCategoryFilter: (payload) =>
    dispatch(categories.actions.setSelectedCategoryFilter(payload)),
});

export default connect(null, mapDispatchToProps)(CardSection);
