import React from 'react';

import {
  ApplePay,
  CreditCard,
  GooglePay,
  PaymentForm,
} from 'react-square-web-payments-sdk';

import { useDispatch, useSelector } from 'react-redux';
import { Box, useTheme } from '@material-ui/core';
import { Formik, Form } from 'formik';

import i18n from 'i18next';

import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { checkout } from '../../../state';

const appId = process.env.GATSBY_APLICATION_ID;
const locationId = process.env.GATSBY_LOCATION_ID;
const sandbox = process.env.GATSBY_SANDBOX_SQUARE_PAYMENT_FORM;

const BulkCheckoutSquare = ({ onPaymentSuccess }) => {
  const theme = useTheme();
  const formData = useSelector(checkout.selectors.selectFormData);
  const dispatch = useDispatch();
  const setFormData = (payload) =>
    dispatch(checkout.actions.setFormData(payload));
  const isLoading = useSelector(checkout.selectors.selectIsLoading);
  React.useEffect(() => {
    if (formData?.culture) {
      i18n.changeLanguage(formData.culture);
    }
  }, [formData]);

  const fieldStyles = [
    {
      color: `${theme.palette.primary.main}`,
      fontSize: `${theme.typography.fontSize}px`,
      fontWeight: `${theme.typography.fontWeightMedium}`,
      letterSpacing: '-0.8px',
      lineHeight: '20px',
      padding: '16px',
      inputContainer: { backgroundColor: 'red' },
    },
  ];
  const [errorMsgs, setErrorMsgs] = React.useState({});
  const [, setIsSubmitting] = React.useState(false);

  const cardNonceResponseReceived = async (details, buyer) => {
    if (details.status !== 'OK') {
      setErrorMsgs(
        'Card does not exist or something went wrong. Please try again later.'
      );
      console.log(errorMsgs);
      setIsSubmitting(false);
      return;
    }

    setErrorMsgs([]);

    onPaymentSuccess(details.token, buyer.token);
    setIsSubmitting(false);
  };

  const createVerificationDetails = () => ({
    amount:
      formData.amount && typeof formData.amount === 'string'
        ? formData.amount
        : formData.amount.toString(),
    currencyCode: formData.currency || 'USD',
    intent: 'CHARGE',
    billingContact: {
      familyName: formData.lastName,
      givenName: formData.firstName,
      email: formData.email,
    },
  });

  const createPaymentRequest = () => {
    return {
      requestShippingAddress: false,
      requestBillingInfo: true,
      currencyCode: formData.currency || 'USD',
      countryCode: formData.country || 'US',
      total: {
        label: 'Pintuna inc.',
        amount: formData.amount.toString(),
        pending: false,
      },
      lineItems: [
        {
          label: 'Total',
          amount: formData.amount.toString(),
          pending: false,
        },
      ],
    };
  };

  return (
    <>
      <PaymentForm
        sandbox={sandbox === 'true'}
        applicationId={appId}
        locationId={locationId}
        cardTokenizeResponseReceived={cardNonceResponseReceived}
        createVerificationDetails={createVerificationDetails}
        inputStyles={fieldStyles}
        createPaymentRequest={createPaymentRequest}
      >
        <Formik
          initialValues={{}}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            setFormData(values);
            actions.setSubmitting(false);
          }}
        >
          {() => {
            return (
              <Form>
                {isLoading ? (
                  <>
                    <Skeleton variant="rectangular" width="100%" height={60} />
                    <Box mt={2} />
                    <Skeleton variant="rectangular" width="100%" height={60} />
                    <Box mt={2} />
                    <Skeleton variant="rectangular" width="100%" height={60} />
                  </>
                ) : (
                  <>
                    <CreditCard />
                    <Box py={2} align="center">
                      <GooglePay />
                    </Box>
                    <Box align="center">
                      <ApplePay />
                    </Box>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </PaymentForm>
    </>
  );
};

BulkCheckoutSquare.propTypes = {
  onPaymentSuccess: PropTypes.func.isRequired,
};
export default BulkCheckoutSquare;
