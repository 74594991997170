import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell as MuiTableCell,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { businesses, checkout } from '../../../state';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '0 0 10px 0',
  },
})(MuiTableCell);

const ChargeBreakdown = ({ business, formData, cardDetails, isAddFunds }) => {
  const { t } = useTranslation();

  if (!business && formData && !cardDetails) {
    return <CircularProgress />;
  }

  const {
    totalAmount,
    customerPaysAmount,
    cardAmount,
    organizationName,
    transactionFee,
    isFeeCovered,
    giveBackAmount,
    fees,
  } = formData;
  const { imageUrl, fees: configFees } = business;
  const savedAmount =
    Number(totalAmount) + Number(fees) - Number(customerPaysAmount);

  const [, setImgUrl] = useState(imageUrl);

  useEffect(() => {
    if (cardDetails.imageUrl) {
      setImgUrl(cardDetails.imageUrl);
    }
  }, [cardDetails]);
  return (
    <Grid item xs={12}>
      <Card raised>
        <CardContent>
          <Typography gutterBottom variant="body2">
            <b>{t('changeBreakdown')}</b>
          </Typography>
          <TableContainer>
            <Table aria-label="Charge breakdown">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {isAddFunds ? t('addFunds') : t('giftCardValue')}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {t('cardValue', {
                      amount: cardAmount,
                      formatParams: {
                        amount: { currency: business.currency },
                      },
                    })}
                  </TableCell>
                </TableRow>
                {isFeeCovered && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {t('creditCardProcessingFee')}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {t('cardValue', {
                        amount: transactionFee,
                        formatParams: {
                          amount: { currency: business.currency },
                        },
                      })}
                    </TableCell>
                  </TableRow>
                )}

                {savedAmount > 0 && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2" style={{ color: 'green' }}>
                        {t('youSave')}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2" style={{ color: 'green' }}>
                        {t('cardValue', {
                          amount: savedAmount.toFixed(2),
                          formatParams: {
                            amount: { currency: business.currency },
                          },
                        })}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {fees > 0 && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2">
                        {t('fees', { fees: configFees })}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2">
                        {t('cardValue', {
                          amount: fees,
                          formatParams: {
                            amount: { currency: business.currency },
                          },
                        })}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography variant="body2">
                      <b>{t('totalAmount')} </b>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <b>
                      {t('cardValue', {
                        amount: customerPaysAmount || totalAmount,
                        formatParams: {
                          amount: { currency: business.currency },
                        },
                      })}
                    </b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {organizationName && (
            <Box pt={1}>
              <Typography variant="body2" component="p">
                <b>
                  {t('cardValue', {
                    amount: giveBackAmount,
                    formatParams: {
                      amount: { currency: business.currency },
                    },
                  })}{' '}
                  {t('willGoTo')} {`${organizationName}`}
                </b>
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

ChargeBreakdown.defaultProps = {
  business: {},
  formData: {},
};

ChargeBreakdown.propTypes = {
  business: PropTypes.shape({
    name: PropTypes.string,
    giveBack: PropTypes.number,
    imageUrl: PropTypes.string,
    currency: PropTypes.string,
    fees: PropTypes.string,
  }),
  formData: PropTypes.shape({
    totalAmount: PropTypes.string,
    customerPaysAmount: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    organizationName: PropTypes.string,
    cardAmount: PropTypes.string,
    fees: PropTypes.string,
    transactionFee: PropTypes.string,
    isFeeCovered: PropTypes.bool,
    isGift: PropTypes.bool,
    giftEmail: PropTypes.string,
    giftFirstName: PropTypes.string,
    giftLastName: PropTypes.string,
    giveBackAmount: PropTypes.string,
  }),
  cardDetails: PropTypes.shape({
    balance: PropTypes.number,
    cardNumber: PropTypes.string,
    businessId: PropTypes.string,
    imageUrl: PropTypes.string,
    // currency: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  isAddFunds: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  business: businesses.selectors.selectBusiness(state),
  formData: checkout.selectors.selectFormData(state),
  cardDetails: businesses.selectors.selectCardDetails(state),
});

export default connect(mapStateToProps)(ChargeBreakdown);
