import React, { useEffect } from 'react';
import { CardMedia, Box, Typography } from '@material-ui/core';
import { Link } from 'gatsby';

import { useParams } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import SEO from '../../seo';
import { SignInForm } from '../../Auth';
import { Content } from '../../layouts';
import logoPath from '../../../images/logo.png';
import { businesses } from '../../../state';

const MerchantLogin = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const business = useSelector(businesses.selectors.selectBusiness);
  const isLoading = useSelector(businesses.selectors.selectIsLoading);
  const dispatch = useDispatch();
  const fetchBusiness = () => dispatch(businesses.actions.fetchBusiness(id));
  useEffect(() => {
    fetchBusiness();
  }, []);
  return (
    <>
      <SEO title="Enterprise Portal Login | Pintuna" />
      <Content md={4} alignMiddle>
        <Box pb={2} align="center">
          <Link to="/">
            {isLoading ? (
              <Skeleton
                variant="rect"
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: 'calc(20% + 19px)',
                  backgroundSize: 'contain',
                  borderRadius: '16px',
                }}
              />
            ) : (
              <CardMedia
                src={business.logoUrl || logoPath}
                component="img"
                alt="Pintuna logo"
                style={{ width: '200px' }}
              />
            )}
          </Link>
        </Box>
        <Box py={2} align="center">
          <Typography variant="body2" color="textSecondary">
            {t('signInToAccessTheEnterprisePortal')}
          </Typography>
        </Box>
        <SignInForm />
      </Content>
    </>
  );
};

export default MerchantLogin;
