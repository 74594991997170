import React from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { Typography, Divider, Box, Container } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { auth } from '../../../state';
import SEO from '../../seo';
import InfoSection from './InfoSection';
// import ChartSection from './ChartSection';

const Dashboard = () => {
  const { t } = useTranslation();
  return (
    <Container disableGutters>
      <SEO title="Dashboard" />
      <Box pb={2}>
        <Typography variant="h6">{t('dashboard')}</Typography>
      </Box>
      <Divider />
      <Box py={2}>
        <InfoSection />
      </Box>
      <Box py={2}>{/* <ChartSection /> */}</Box>
    </Container>
  );
};

// Dashboard.propTypes = {
//   user: PropTypes.shape({
//     firstName: PropTypes.string,
//   }).isRequired,
// };

const mapStateToProps = (state) => ({
  user: auth.selectors.selectUser(state),
});
export default connect(mapStateToProps)(Dashboard);
