import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { getOr, isEmpty } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Button,
  Grid,
  Box,
  CircularProgress,
  // MenuItem,
} from '@material-ui/core';

import { checkout, alert } from '../../../state';

const cloverUrl = process.env.GATSBY_CLOVER_SCRIPT;

const hasCloverScript = () =>
  document.querySelector('script#clover-sdk') !== null;

const hideCloverFooter = () => {
  const cloverFooter = document.querySelector('.clover-footer');
  if (cloverFooter) {
    cloverFooter.style.display = 'none';
  }
};
const appendCloverScript = (setScriptLoaded) => {
  const script = document.createElement('script');
  script.id = 'clover-sdk';
  script.type = 'text/javascript';
  script.src = cloverUrl;
  script.onload = () => {
    setScriptLoaded(true);
  };
  // eslint-disable-next-line no-console
  script.onerror = () => console.error('Failed to Load Clover script!');
  document.body.appendChild(script);
};
const unmountClover = () => {
  const cloverFooter = document.querySelector('.clover-footer');
  const cloverScript = document.querySelector('script#clover-sdk');
  const allCloverFrames = document.querySelectorAll(
    'iframe[title^="INTERMEDIATE"]'
  );
  if (cloverFooter) {
    cloverFooter.remove();
  }
  if (cloverScript) {
    cloverScript.remove();
  }
  if (!isEmpty(allCloverFrames)) {
    Array.from(allCloverFrames).forEach((frame) => frame.parentNode.remove());
  }
};

const unmountFields = () => {
  const allFrames = document.querySelectorAll('#clover-form iframe');
  if (!isEmpty(allFrames)) {
    Array.from(allFrames).forEach((frame) =>
      frame.parentNode.removeChild(frame)
    );
  }
};

let clover;

const BulkCheckoutClover = ({ onPaymentSuccess }) => {
  const { t } = useTranslation();
  const [scriptLoaded, setScriptLoaded] = React.useState(false);
  const [cloverIsReady, setCloverIsReady] = React.useState(false);
  const dispatch = useDispatch();
  const formData = useSelector(checkout.selectors.selectFormData);

  const getCloverIframeToken = (payload) =>
    dispatch(checkout.actions.getCloverIframeToken(payload));
  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const [errors, setErrors] = React.useState({
    CARD_NUMBER: '',
    CARD_DATE: '',
    CARD_CVV: '',
    CARD_POSTAL_CODE: '',
  });
  const { totalAmount, customerPaysAmount } = formData;

  const cardNumberListner = (payload) => {
    const error = getOr('', ['CARD_NUMBER', 'error'], payload);
    if (errors) {
      setErrors({ ...errors, CARD_NUMBER: error });
    }
  };
  const cardDateListner = (event) => {
    const error = getOr('', ['CARD_DATE', 'error'], event);
    if (errors) {
      setErrors({ ...errors, CARD_DATE: error });
    }
  };
  const cardCvvListner = (event) => {
    const error = getOr('', ['CARD_CVV', 'error'], event);
    if (errors) {
      setErrors({ ...errors, CARD_CVV: error });
    }
  };
  const cardPostalCodeListner = (event) => {
    const error = getOr('', ['CARD_POSTAL_CODE', 'error'], event);
    if (errors) {
      setErrors({ ...errors, CARD_POSTAL_CODE: error });
    }
  };

  React.useEffect(() => {
    if (!hasCloverScript()) {
      appendCloverScript(setScriptLoaded);
    }
    return () => {
      if (hasCloverScript()) {
        unmountClover();
        setScriptLoaded(false);
        setCloverIsReady(false);
      }
    };
  }, []);
  React.useEffect(() => {
    const { businessId } = formData;
    if (scriptLoaded) {
      // TODO: isLoading State
      const createCloverInstance = async () => {
        if (businessId) {
          const res = await getCloverIframeToken(businessId);
          if (res.payload?.token) {
            const CloverInstance = window.Clover;
            clover = new CloverInstance(res.payload.token);
            setCloverIsReady(true);
          } else {
            openAlert({
              message:
                res?.error?.message ||
                t('weCannotProcessPaymentsPleaseTryLater'),
              severity: 'error',
            });
          }
        }
      };
      createCloverInstance();
    }
  }, [scriptLoaded]);

  React.useEffect(() => {
    if (cloverIsReady) {
      const elements = clover.elements();
      // Create
      const cardNumber = elements.create('CARD_NUMBER');
      const cardDate = elements.create('CARD_DATE');
      const cardCvv = elements.create('CARD_CVV');
      const cardPostalCode = elements.create('CARD_POSTAL_CODE');
      // Mount
      cardNumber.mount('#card-number');
      cardDate.mount('#card-date');
      cardCvv.mount('#card-cvv');
      cardPostalCode.mount('#postal-code');
      // Add event listener
      cardNumber.addEventListener('blur', cardNumberListner);
      cardDate.addEventListener('blur', cardDateListner);
      cardCvv.addEventListener('blur', cardCvvListner);
      cardPostalCode.addEventListener('blur', cardPostalCodeListner);
      hideCloverFooter();
      return () => {
        cardNumber.removeEventListener('blur', cardNumberListner);
        cardDate.removeEventListener('blur', cardDateListner);
        cardCvv.removeEventListener('blur', cardCvvListner);
        cardPostalCode.removeEventListener('blur', cardPostalCodeListner);
        unmountFields();
      };
    }
    return undefined;
  }, [cloverIsReady]);

  if (!cloverIsReady) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Box display="flex" alignItems="center" height="300px">
            <CircularProgress />
          </Box>
        </Grid>
      </Grid>
    );
  }
  return (
    <Formik
      initialValues={{
        city: '',
        line1: '',
        state: '',
        postal_code: '',
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        await clover.createToken().then(async (result) => {
          if (result.errors) {
            setErrors(result.errors);
            actions.setSubmitting(false);
            return null;
          }
          await onPaymentSuccess(result.token);
          actions.setSubmitting(false);
          return null;
        });
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <Form id="clover-form" onSubmit={handleSubmit}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Grid item xs={12}>
                <Box pb={2}>
                  <Grid container item xs={12}>
                    <TextField
                      id="card-number"
                      label={t('cardNumber')}
                      name="ccnumber"
                      variant="outlined"
                      error={!!errors.CARD_NUMBER}
                      helperText={errors.CARD_NUMBER}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputComponent: 'div',
                      }}
                    />
                  </Grid>
                </Box>
                <Box pb={2}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={4}>
                      <TextField
                        id="card-date"
                        label={t('expirationDate')}
                        name="ccexp"
                        variant="outlined"
                        fullWidth
                        error={!!errors.CARD_DATE}
                        helperText={errors.CARD_DATE}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: 'div',
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="card-cvv"
                        label={t('cvc')}
                        name="cvc"
                        variant="outlined"
                        fullWidth
                        error={!!errors.CARD_CVV}
                        helperText={errors.CARD_CVV}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: 'div',
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="postal-code"
                        label={t('postalCode')}
                        name="postalCode"
                        variant="outlined"
                        fullWidth
                        error={!!errors.CARD_POSTAL_CODE}
                        helperText={errors.CARD_POSTAL_CODE}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: 'div',
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  id="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  {isSubmitting ? (
                    <CircularProgress size={22} />
                  ) : (
                    t('pay', {
                      amount: customerPaysAmount || totalAmount,
                      currency: formData.currency,
                    })
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

BulkCheckoutClover.propTypes = {
  onPaymentSuccess: PropTypes.func.isRequired,
};

export default BulkCheckoutClover;
