import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  CircularProgress,
  Box,
  Button,
  makeStyles,
} from '@material-ui/core';
import { debounce } from 'lodash/fp';
import { GetApp } from '@material-ui/icons';

import { cards, types, categories } from '../../../state';

const useStyles = makeStyles((theme) => ({
  loadMore: {
    border: `0.5px solid ${theme.palette.divider}`,
    borderRadius: 15,
    [theme.breakpoints.down('sm')]: {
      height: '60px',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '179.1px',
      height: 'calc(100% - 24px)',
    },
  },
}));

const CardList = ({
  cardList,
  isLoading,
  isLoadingMore,
  isAll,
  loadMore,
  handleNext,
  setSelectedCard,
  flipCard,
  selectedType,
  selectedCategory,
}) => {
  const FlipCard = flipCard;
  const classes = useStyles();
  const debouncedFetch = debounce(300, () =>
    loadMore({ selectedType, selectedCategory })
  );

  return (
    <>
      <Grid container spacing={4} alignItems="center">
        {cardList.map((card) => {
          const { id } = card;
          return (
            <Grid item key={id || 'comingsoon'} xs={12} sm={6} md={3}>
              <FlipCard
                handleNext={handleNext}
                card={card}
                setSelectedCard={setSelectedCard}
                isLoading={isLoading}
              />
            </Grid>
          );
        })}
      </Grid>
      {isAll && (
        <Box py={4} display="block" align="center">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={debouncedFetch}
            disabled={isLoadingMore}
            endIcon={
              isLoadingMore ? <CircularProgress size={16} /> : <GetApp />
            }
          >
            Load More
          </Button>
        </Box>
      )}
    </>
  );
};

CardList.defaultProps = {
  selectedType: '',
  selectedCategory: '',
};

CardList.propTypes = {
  cardList: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAll: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  setSelectedCard: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  flipCard: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  selectedType: PropTypes.string,
  selectedCategory: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  loadMore: (payload) => dispatch(cards.actions.loadMore(payload)),
  setSelectedCard: (payload) =>
    dispatch(cards.actions.setSelectedCard(payload)),
});

const mapStateToProps = (state) => ({
  cardList: cards.selectors.selectCards(state),
  selectedType: types.selectors.selectSelectedType(state),
  selectedCategory: categories.selectors.selectSelectedCategory(state),
  isAll: cards.selectors.selectIsAll(state),
  isLoading: cards.selectors.selectIsLoading(state),
  isLoadingMore: cards.selectors.selectIsLoadingMore(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardList);
