import * as Yup from 'yup';
import i18n from 'i18next';

const UserInfoSchema = (maxAmount, minAmount, customAmount) =>
  Yup.object().shape({
    isGift: Yup.boolean(),
    giftEmail: Yup.string().when('isGift', {
      is: (isGift) => isGift === true,
      then: Yup.string()
        .email(i18n.t('invalidEmail'))
        .required(i18n.t('required')),
      otherwise: Yup.string(),
    }),
    giftFirstName: Yup.string().when('isGift', {
      is: (isGift) => isGift === true,
      then: Yup.string()
        .min(2, i18n.t('tooShort'))
        .max(50, i18n.t('tooLong'))
        .required(i18n.t('required')),
      otherwise: Yup.string(),
    }),
    giftLastName: Yup.string().when('isGift', {
      is: (isGift) => isGift === true,
      then: Yup.string()
        .min(2, i18n.t('tooShort'))
        .max(50, i18n.t('tooLong'))
        .required(i18n.t('required')),
      otherwise: Yup.string(),
    }),
    firstName: Yup.string()
      .min(2, i18n.t('tooShort'))
      .max(50, i18n.t('tooLong'))
      .required(i18n.t('required')),
    lastName: Yup.string()
      .min(2, i18n.t('tooShort'))
      .max(50, i18n.t('tooLong'))
      .required(i18n.t('required')),
    email: Yup.string()
      .email(i18n.t('invalidEmail'))
      .required(i18n.t('required')),
    amount: customAmount
      ? Yup.number()
          .typeError(i18n.t('amountMustBeANumber'))
          .min(minAmount, `${i18n.t('minumumAmount')} $${minAmount}`)
          .max(maxAmount, `${i18n.t('maximumAmountAllowed')} $${maxAmount}`)
          .required(i18n.t('required'))
      : Yup.number()
          .typeError(i18n.t('amountMustBeANumber'))
          .required(i18n.t('required')),
  });

export default UserInfoSchema;
