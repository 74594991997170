/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Grid, Typography, Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowBack } from '@material-ui/icons';
import CheckoutSquare from './CheckoutSquare';
import CheckoutClover from './CheckoutClover';

import OrderDetails from './OrderDetails';

import PoweredBySquare from '../../../images/poweredBySquare.svg';
import PoweredByClover from '../../../images/poweredByClover.png';

import { Content } from '../../layouts';
import SEO from '../../seo';
import ChargeBreakdown from './ChargeBreakdown';
import TermsAndConditions from './TermsAndConditions';
import { businesses, cards, checkout } from '../../../state';

const useStyles = makeStyles((theme) => ({
  squareLogo: {
    height: '20px',
  },
  stripeLogo: {
    maxHeight: '30px',
  },
  cloverLogo: {
    maxHeight: '20px',
  },
  item1: {
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  item2: {
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
}));

const Checkout = () => {
  const { t } = useTranslation();
  const business = useSelector(businesses.selectors.selectBusiness);
  const cardDetails = useSelector(cards.selectors.selectSelectedCard);
  const dispatch = useDispatch();
  const setStep = (step) => dispatch(checkout.actions.setStep(step));
  const { name, partner } = business;
  const isSquareCheckout = partner === 'Square' || partner === 'BHN';
  const isCloverCheckout = partner === 'Clover';
  const classes = useStyles();

  const isAddFunds = Boolean(cardDetails.cardNumber || cardDetails.code);

  return (
    <>
      <SEO title="Checkout" />
      <Content style={{ minHeight: 'calc(100vh - 64px - 196px)' }}>
        <Box pb={4}>
          <IconButton
            aria-label="back"
            onClick={() => setStep(0)}
            style={{ position: 'absolute', top: 80, left: 30 }}
          >
            <ArrowBack />
          </IconButton>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Box my={3}>
              <Typography display="block" align="center" variant="h5">
                {name}
                {isAddFunds && (
                  <>
                    : <b>{t('addFunds')}</b>
                  </>
                )}
              </Typography>
              <Typography display="block" align="center" variant="subtitle1">
                {t('confirmOrderDetail')}
              </Typography>
            </Box>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={6}
          >
            <Grid item xs={12} sm={5} className={classes.item2}>
              <OrderDetails isAddFunds={isAddFunds} />
            </Grid>
            <Grid item xs={12} sm={7} className={classes.item1}>
              <Box mb={3}>
                <ChargeBreakdown isAddFunds={isAddFunds} />
              </Box>

              {isSquareCheckout && (
                <>
                  <CheckoutSquare isAddFunds={isAddFunds} />
                  <Box py={2} align="center">
                    <img
                      src={PoweredBySquare}
                      alt="Powered By Square"
                      className={classes.squareLogo}
                    />
                  </Box>
                </>
              )}
              {isCloverCheckout && (
                <>
                  <CheckoutClover isAddFunds={isAddFunds} />
                  <Box py={2} align="center">
                    <img
                      src={PoweredByClover}
                      alt="Powered By Clover"
                      className={classes.cloverLogo}
                    />
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <Box mt={1}>
                <TermsAndConditions />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Content>
    </>
  );
};
export default Checkout;
