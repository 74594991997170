import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { AccountBalance, CardGiftcard } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { InfoCard } from '../../custom';
import { stats, enterprise } from '../../../state';

const InfoSection = ({ isLoading }) => {
  const enterpriseStats = useSelector(stats.selectors.selectStats);
  const info = useSelector(enterprise.selectors.selectInfo);
  const currency = info.giftCardConfigs?.[0]?.currency || 'USD';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getStats = (payload) => dispatch(stats.actions.getStats(payload));
  useEffect(() => {
    getStats();
  }, []);
  return (
    <Grid container spacing={4}>
      <Grid container item xs={12} md={4}>
        <InfoCard
          label={t('#ofGiftCards')}
          icon={<CardGiftcard fontSize="large" />}
          isLoading={isLoading}
        >
          {enterpriseStats.giftCardsCount || 0}
        </InfoCard>
      </Grid>
      {/* <Grid container item xs={12} md={4}> */}
      {/*   <InfoCard */}
      {/*     label="Average Gift Card Value" */}
      {/*     icon={<AttachMoney fontSize="large" />} */}
      {/*     isLoading={isLoading} */}
      {/*     // href="/dashboard/nonprofit-contributions" */}
      {/*   > */}
      {/*     {t('cardValue', { */}
      {/*       amount: enterpriseStats.averageValue, */}
      {/*       formatParams: { */}
      {/*         amount: { currency }, */}
      {/*       }, */}
      {/*     })} */}
      {/*   </InfoCard> */}
      {/* </Grid> */}
      <Grid container item xs={12} md={4}>
        <InfoCard
          label={t('totalCurrentBalance')}
          icon={<AccountBalance fontSize="large" />}
          isLoading={isLoading}
        >
          {t('cardValue', {
            amount: enterpriseStats.giftCardsBalance || 0,
            formatParams: {
              amount: { currency },
            },
          })}
        </InfoCard>
      </Grid>
      {/* <Grid container item xs={12} md={3}>
        <InfoCard
          label="TOTAL AMOUNT SPEND ON CARDS"
          icon={<AttachMoney fontSize="large" />}
          isLoading={isLoading}
        >
          $2351.00
        </InfoCard>
      </Grid> */}
    </Grid>
  );
};

InfoSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: stats.selectors.selectIsLoading(state),
});

export default connect(mapStateToProps)(InfoSection);
