import React from 'react';
import PropTypes from 'prop-types';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  Grid,
  CircularProgress,
  Box,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import ReactCardFlip from 'react-card-flip';
import { Step0Validation } from './validation';
import CardSection from '../components';

import { CustomImgCard, CustomInputField } from '../../custom';

const FlipCard = ({ handleNext, setSelectedCard, card, isLoading }) => {
  const [isFlipped, setIsFlipped] = React.useState(false);

  const { minAmount, maxAmount, id, name, imageUrl, giveBack } = card;
  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      {id ? (
        <CustomImgCard
          clickable
          name={name}
          imageUrl={imageUrl}
          giveBack={giveBack}
          isLoading={isLoading}
          details
          onClick={() => setIsFlipped((prev) => !prev)}
        />
      ) : (
        <CustomImgCard
          clickable
          name={name}
          imageUrl={imageUrl}
          giveBack={giveBack || ''}
          details
          onClick={() => setIsFlipped((prev) => !prev)}
        />
      )}

      <Box
        height="180px"
        // onMouseLeave={() => setIsFlipped(false)}
        align="center"
      >
        <Box pb={2}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <IconButton
                aria-label="Back"
                onClick={() => setIsFlipped(false)}
                style={{ padding: 6 }}
              >
                <ArrowBack fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" color="textSecondary">
                {name}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Formik
          enableReinitialize
          validationSchema={() => Step0Validation(maxAmount, minAmount)}
          initialValues={{
            amount: '',
          }}
          onSubmit={async ({ amount }) => {
            const numAmount = Number(amount);
            const payload = { ...card, amount: numAmount };
            setSelectedCard(payload);
            handleNext();
          }}
        >
          {({ isSubmitting, handleSubmit }) => {
            return (
              <Form>
                <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    fullWidth
                    required
                    label="Enter Amount"
                    name="amount"
                    startAdornment="$"
                    trimLeadingZeros
                    variant="outlined"
                  />
                </Box>
                <Box pb={2}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    size="large"
                  >
                    {isSubmitting ? <CircularProgress size={24} /> : 'Continue'}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </ReactCardFlip>
  );
};

const Step0 = ({ handleNext }) => (
  <CardSection flipCard={FlipCard} handleNext={handleNext} />
);

FlipCard.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    imageUrl: PropTypes.string,
    giveBack: PropTypes.number,
    minAmount: PropTypes.number,
    maxAmount: PropTypes.number,
  }).isRequired,
  handleNext: PropTypes.func.isRequired,
  setSelectedCard: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

Step0.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default Step0;
