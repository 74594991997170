import React, { useState } from 'react';
import {
  Typography,
  Box,
  Divider,
  Grid,
  Container,
  Tabs,
  IconButton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { Skeleton, TabContext, TabPanel } from '@material-ui/lab';
import SwipeableViews from 'react-swipeable-views/lib/SwipeableViews';
import { ArrowBack } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import SEO from '../../seo';
import { businesses, cards, enterprise } from '../../../state';
import StyledTab from '../components/StyledTab';
import IssueGiftCard from './IssueGiftCard';
import SelectGiftCardConfig from './SelectGiftCardConfig';
import CardsSold from './CardsSold';
import BulkIssue from '../BulkCheckout/BulkIssue';

const SendToEmployees = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(cards.actions.emptySelectedCard());
  }, []);
  const [formTab, setTab] = useState(0);
  const { t } = useTranslation();
  const business = useSelector(businesses.selectors.selectBusiness);
  const enterpriseData = useSelector(enterprise.selectors.selectInfo);
  const giftCardConfigs = useSelector(
    enterprise.selectors.selectGiftCardConfigs
  );
  const giftCardConfig = useSelector(businesses.selectors.selectBusiness);
  const isLoading = useSelector(enterprise.selectors.selectIsLoading);
  React.useEffect(() => {
    if (business.id && formTab === 0) {
      // setTab(1);
    }
  }, [business]);

  React.useEffect(() => {
    if (giftCardConfigs.length === 1) {
      setTab(1);
    }
  }, [giftCardConfigs]);

  return (
    <Container disableGutters>
      <SEO title="Send Gift Cards" />
      <Box pb={2}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Grid container>
              <Grid item>
                <IconButton aria-label="Back" style={{ padding: 6 }}>
                  <ArrowBack fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">{t('sendToEmployees')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            justifyContent="flex-end"
            alignContent="flex-end"
            direction="row"
          >
            <Typography variant="h6">
              {t('merchant')}: {giftCardConfig.merchantName}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Divider />
        {isLoading ? (
          <Skeleton variant="rect" width="100%" height="50px" />
        ) : (
          <>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Tabs
                  value={formTab}
                  onChange={(event, newValue) => {
                    setTab(newValue);
                  }}
                  TabIndicatorProps={{ style: { background: '#0969da' } }}
                  aria-label="Select settings tab"
                >
                  <StyledTab
                    disableRipple
                    id="initialSetup"
                    aria-controls="values"
                    label={t('selectMerchant')}
                    style={{
                      display: giftCardConfigs.length > 1 ? 'block' : 'none',
                    }}
                  />
                  <StyledTab
                    disableRipple
                    id="searchUpdate"
                    aria-controls="values"
                    label={t('searchUpdate')}
                    disabled={!business.id}
                  />
                  <StyledTab
                    disableRipple
                    id="issueSc"
                    aria-controls="values"
                    label={t('issueAGiftCard')}
                    disabled={!business.id}
                  />
                  <StyledTab
                    disableRipple
                    id="bulkIssue"
                    aria-controls="values"
                    label={t('issueInBulk')}
                    disabled={!business.id}
                  />
                </Tabs>
              </Grid>
              <Grid item alignItems="center" style={{ display: 'flex' }}>
                <Typography variant="h6">
                  {t('availableCredit')} ={' '}
                  {t('cardValue', {
                    amount: enterpriseData.balance,
                    formatParams: {
                      amount: { currency: business.currency },
                    },
                  })}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <TabContext value={formTab.toString()}>
              <SwipeableViews index={formTab} onChangeIndex={setTab}>
                <TabPanel value={formTab.toString()} index={0}>
                  <SelectGiftCardConfig />
                </TabPanel>
                <TabPanel value={formTab.toString()} index={1}>
                  <CardsSold />
                </TabPanel>
                <TabPanel value={formTab.toString()} index={2}>
                  <IssueGiftCard />
                </TabPanel>
                <TabPanel value={formTab.toString()} index={3}>
                  <BulkIssue />
                </TabPanel>
              </SwipeableViews>
            </TabContext>
          </>
        )}
      </Box>
    </Container>
  );
};

export default SendToEmployees;
