import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Card, CardContent, Typography, Box } from '@material-ui/core';

import PropTypes from 'prop-types';
import defaultCardImage from '../../../images/defaultGiftCard.jpg';
import { CustomImgCard } from '../../custom';
import { businesses, checkout, cards } from '../../../state';

const BulkOrderDetails = ({ data }) => {
  const { t } = useTranslation();

  // if (!business && formData && !cardDetails) {
  //   return <CircularProgress />;
  // }

  const giftCardConfig = useSelector(businesses.selectors.selectBusiness);

  const { name, imageUrl } = giftCardConfig;

  const cardDetails = useSelector(cards.selectors.selectSelectedCard);

  const [imgUrl, setImgUrl] = useState(imageUrl);

  useEffect(() => {
    if (cardDetails.imageUrl) {
      setImgUrl(cardDetails.imageUrl);
    }
  }, [cardDetails]);
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={4}
    >
      <Grid item xs={12}>
        <Card raised>
          <Box display="block" align="middle">
            <CustomImgCard
              name={name || 'default image'}
              imageUrl={imgUrl || `${defaultCardImage}`}
              elevation={0}
              square
              width="272px"
            />
          </Box>
          <CardContent>
            <Typography display="block" variant="subtitle2">
              {`${t('#ofGiftCards')} = ${data.length}`}
            </Typography>
            <Box mt={1}>
              <Typography display="block" variant="subtitle2">
                {t('giftCardWillBeEmailed')}
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography
                display="block"
                variant="body2"
                style={{ color: '#aaa' }}
              >
                {t('bulkIssueNote')}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

BulkOrderDetails.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  business: businesses.selectors.selectBusiness(state),
  formData: checkout.selectors.selectFormData(state),
  cardDetails: cards.selectors.selectSelectedCard(state),
});

export default connect(mapStateToProps)(BulkOrderDetails);
