/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Grid,
  Typography,
  Box,
  Link,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ShowMoreText from 'react-show-more-text';
import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { CustomImgCard } from '../../custom';
import { businesses } from '../../../state';
import UserInfoForm from './UserInfoForm';

const useStyles = makeStyles((theme) => ({
  fsBackground: {
    background: `#e4f9fc`,
    minHeight: '170px',
    maxHeight: '170px',
    width: '100%',
    position: 'absolute',
    left: 0,
    zIndex: -1,
  },
  checkBalance: {
    position: 'absolute',
    right: '5.7%',
    top: '50%',
    width: '250px',
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    transform: 'translateY(-50%)',
    '&:hover': {
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      top: 'auto',
      right: 0,
      fontSize: '13px',
    },
  },
  checkBalanceWrapper: {
    position: 'absolute',
    width: '100%',
    top: '65px',
    left: 0,
    height: '170px',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      top: 'auto',
      height: 'auto',
      position: 'relative',
      paddingTop: '25px',
    },
  },
}));

const CardInfoSection = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [expand, setExpand] = React.useState(false);
  const business = useSelector(businesses.selectors.selectBusiness);
  const isLoading = useSelector(businesses.selectors.selectIsLoading);
  // const dispatch = useDispatch();
  // const setFormData = (payload) =>
  //   dispatch(checkout.actions.setFormData(payload));

  const { name, imageUrl, terms, description } = business;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = () => {
    setExpand(!expand);
  };

  return (
    <>
      <div className={classes.fsBackground} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Box pt={2} display="block" align="center">
              <Typography variant="h5" color="primary">
                {isLoading ? <Skeleton width="190px" /> : business.name}
              </Typography>
              {!!business.giveBack && (
                <Typography variant="subtitle1" color="primary">
                  {isLoading ? (
                    <Skeleton width="250px" />
                  ) : (
                    <>
                      {`${parseFloat((business.giveBack * 100).toFixed(2))}%`}{' '}
                      {t('willGoTo')}{' '}
                    </>
                  )}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Box py={2}>
                <CustomImgCard
                  isLoading={isLoading}
                  name={name}
                  width="270px"
                  imageUrl={imageUrl}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                display="block"
                align="center"
                variant="body2"
                component="div"
              >
                {isLoading ? (
                  <>
                    <Skeleton />
                    <Skeleton />
                  </>
                ) : (
                  <ShowMoreText
                    lines={2}
                    more={t('showMore')}
                    less={t('showLess')}
                    onClick={onClick}
                    expanded={false}
                  >
                    {description}
                  </ShowMoreText>
                )}
              </Typography>
            </Grid>

            {isLoading ? (
              <Skeleton width="140px" />
            ) : (
              !isEmpty(terms) && (
                <Grid item xs={12}>
                  <Box pt={1}>
                    <Typography display="block" align="center" variant="body2">
                      <Link
                        component="button"
                        underline="hover"
                        style={{ color: 'blue' }}
                        onClick={handleOpen}
                      >
                        {t('termsAndConditions')}
                      </Link>
                    </Typography>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      scroll="paper"
                      aria-labelledby="terms and conditions"
                      aria-describedby="terms and conditions"
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                      <DialogTitle>{t('termsAndConditions')}</DialogTitle>
                      <DialogContent dividers>
                        <DialogContentText tabIndex={-1} component="div">
                          <Typography variant="body2" component="div">
                            {terms}
                          </Typography>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          {t('iAgree')}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* {!isBhnPartner && ( */}
      {/*   <div className={classes.checkBalanceWrapper}> */}
      {/*     <Link */}
      {/*       className={classes.checkBalance} */}
      {/*       underline="hover" */}
      {/*       onClick={handleCheckBalance} */}
      {/*     > */}
      {/*       {t('checkBalanceAddFunds')} */}
      {/*     </Link> */}
      {/*   </div> */}
      {/* )} */}
      <Box pb={2}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <UserInfoForm />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CardInfoSection;
