import React from 'react';
import { Box, Grid, Button, CircularProgress } from '@material-ui/core';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import getSymbolFromCurrency from 'currency-symbol-map';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import SEO from '../../seo';
import { CustomInputField } from '../../custom';
import { businesses } from '../../../state';

const validationSchema = () =>
  Yup.object().shape({
    'First Name': Yup.string().required('This field is required'),
    'Last Name': Yup.string().required('This field is required'),
    'Personal Message': Yup.string().max(255, 'Too Long!'),
    Email: Yup.string()
      .required('This field is required')
      .email('Please enter valid email'),
    Amount: Yup.number().required('This field is required'),
  });

const EditRowDialog = ({ data, saveData }) => {
  // const dispatch = useDispatch();
  const { t } = useTranslation();

  const { currency } = useSelector(businesses.selectors.selectBusiness);
  const currencySymbol = getSymbolFromCurrency(currency);

  const onSubmit = async (values, formikHelper) => {
    formikHelper.setSubmitting(true);
    const val = { ...values, Amount: Number(values.Amount) };
    await saveData(val, data?.index);
    formikHelper.setSubmitting(false);
  };

  return (
    <>
      <SEO title="Detail Issue eCard" />
      <Formik
        validationSchema={() => validationSchema(currencySymbol)}
        onSubmit={onSubmit}
        initialValues={data?.value || {}}
      >
        {({ isSubmitting, handleSubmit, errors, setFieldValue, values }) => {
          return (
            <>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      trim
                      fullWidth
                      required
                      label={t('firstName')}
                      autoComplete="off"
                      name="First Name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      trim
                      fullWidth
                      required
                      label={t('lastName')}
                      autoComplete="off"
                      name="Last Name"
                      variant="outlined"
                    />
                  </Grid>
                  <Box mb={1} />
                  <Grid item xs={12} md={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      trim
                      fullWidth
                      required
                      label={t('amountText')}
                      autoComplete="off"
                      name="Amount"
                      variant="outlined"
                      startAdornment={currencySymbol}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      trim
                      fullWidth
                      required
                      label={t('email')}
                      autoComplete="off"
                      name="Email"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      label={t('personalMessage')}
                      name="Personal Message"
                      variant="outlined"
                      multiline
                      rows={4}
                      inputProps={{
                        maxLength: 255,
                      }}
                      helperText={`${
                        values['Personal Message']?.length || 0
                      }/255`}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      format="YYYY-MM-DD"
                      disablePast
                      minDate={moment().add(1, 'days')}
                      required
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          error: Boolean(errors.deliverAt),
                          helperText: errors.deliverAt,
                          fullWidth: true,
                        },
                      }}
                      value={
                        values['Send Date (YYYY-MM-DD)']
                          ? moment(values['Send Date (YYYY-MM-DD)'])
                          : null
                      }
                      onChange={(val) => {
                        setFieldValue(
                          'Send Date (YYYY-MM-DD)',
                          val.isValid() ? val.format('YYYY/MM/DD') : ''
                        );
                      }}
                      name="Send Date (YYYY-MM-DD)"
                      label={`${t('deliverAt')} *`}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        type="submit"
                        disabled={isSubmitting}
                        style={{ float: 'right' }}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={24} />
                        ) : (
                          t('save')
                        )}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </>
  );
};

EditRowDialog.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.object,
    index: PropTypes.number,
  }).isRequired,
  saveData: PropTypes.func.isRequired,
};

export default EditRowDialog;
