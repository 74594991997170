import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Chip, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { categories, cards, types } from '../../../state';

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

const CategoryFilters = ({
  selectedCategory,
  isLoading,
  getCards,
  setSelectedCategoryFilter,
  selectedType,
  categoriesList,
  filterByCategory,
}) => {
  const classes = useStyles();
  // console.log(selectedCategory, selectedType);
  const handleDelete = () => {
    getCards(selectedType);
    setSelectedCategoryFilter('');
  };

  const handleCategoryClick = (categoryId) => {
    // console.log(categoryId, selectedType);
    setSelectedCategoryFilter(categoryId);
    filterByCategory({ typeId: selectedType, categoryId });
  };

  return categoriesList.map(({ name, id }) =>
    !name || isLoading ? (
      <Skeleton
        className={classes.chip}
        key={id}
        width="60px"
        height="24px"
        variant="text"
      />
    ) : (
      <Chip
        className={classes.chip}
        key={id}
        variant={selectedCategory === id ? 'default' : 'outlined'}
        clickable
        size="small"
        label={name}
        onDelete={selectedCategory === id ? handleDelete : null}
        onClick={() => handleCategoryClick(id)}
      />
    )
  );
};

CategoryFilters.defaultProps = {
  categoriesList: [],
  selectedType: '',
  selectedCategory: '',
};

CategoryFilters.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  selectedCategory: PropTypes.string,
  categoriesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedCategoryFilter: (payload) =>
    dispatch(categories.actions.setSelectedCategoryFilter(payload)),
  getCards: () => dispatch(cards.actions.getCards()),
  filterByCategory: (payload) =>
    dispatch(cards.actions.filterByCategory(payload)),
});

const mapStateToProps = (state) => ({
  selectedType: types.selectors.selectSelectedType(state),
  selectedCategory: categories.selectors.selectSelectedCategory(state),
  categoriesList: categories.selectors.selectCategories(state),
  isLoading: categories.selectors.selectIsLoading(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryFilters);
