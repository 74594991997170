import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { TransactionTable } from '../../custom';
import { businesses, enterprise } from '../../../state';

const CardsSold = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const giftCardConfigDetail = useSelector(
    businesses.selectors.selectCardDetails
  );

  const [params, setParams] = useState({ page: 1, pageSize: 5 });
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const handleGetList = async () => {
    setIsLoading(true);
    const response = await dispatch(enterprise.actions.getTransactions(params));
    if (response.payload) {
      setData(response.payload.data || []);
      setTotal(response.payload.total || 0);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    setTimeout(handleGetList);
  }, [giftCardConfigDetail, JSON.stringify(params)]);

  const search = () => {
    setParams({ ...params, keyword });
  };

  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, pageSize: event.target.value, page: 1 });
  };

  const cols = [
    t('transactionDate'),
    t('deliverAt'),
    t('merchant'),
    t('firstName'),
    t('lastName'),
    t('email'),
    t('order#'),
    t('giftCardCode'),
    t('amountText'),
  ];
  const rowKeys = [
    'createdAt',
    'deliverAt',
    'merchantName',
    'giftFirstName',
    'giftLastName',
    'giftEmail',
    'orderNumber',
    'code',
    'amount',
  ];

  const mods = {
    createdAt: (val) => moment(`${val}Z`).format('MMMM DD YYYY, HH:mm:ss'),
    deliverAt: (val) => (val ? moment(`${val}Z`).format('MMMM DD YYYY') : ''),
    amount: (val, row) =>
      `${t('cardValue', {
        amount: val.toFixed(2),
        formatParams: {
          amount: { currency: row.currency || 'USD' },
        },
      })}`,
  };

  const pagination = {
    total,
    pageSize: params.pageSize,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const searchBox = () => (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item style={{ minWidth: 300 }}>
        <Box mx={1}>
          <TextField
            value={keyword}
            fullWidth
            type="string"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            name="keyword"
            onChange={(val) => {
              setKeyword(val.target.value);
            }}
          />
        </Box>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={search}
          type="submit"
        >
          {t('search')}
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={4}>
      <Grid container item xs={12}>
        <TransactionTable
          cols={cols}
          rowKeys={rowKeys}
          data={data}
          mods={mods}
          isLoading={isLoading}
          noDataMsg={t('noData')}
          pagination={pagination}
          searchBox={searchBox}
        />
      </Grid>
    </Grid>
  );
};

export default CardsSold;
