import React from 'react';
import { Typography, Box, Divider, Container } from '@material-ui/core';

import EmployeeList from './EmployeeList';
import SEO from '../../seo';

const ManageEmployees = () => {
  return (
    <Container disableGutters>
      <SEO title="Manage Employees" />
      <Box pb={2}>
        <Typography variant="h6">Manage Employees</Typography>
      </Box>
      <Divider />
      <Box py={2}>
        <EmployeeList />
      </Box>
    </Container>
  );
};

export default ManageEmployees;
