import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  CircularProgress,
  Box,
  Button,
  TextField,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { Autocomplete } from '@material-ui/lab';
// import { Autocomplete } from 'formik-material-ui-lab';
import { PreLoader } from '../../custom';
import { orgs, cards } from '../../../state';
import { Step1Validation } from './validation';

const Step1 = ({
  organisations,
  getOrgs,
  isLoading,
  handleNext,
  setSelectedCard,
  selectedCard,
}) => {
  React.useEffect(() => {
    getOrgs();
  }, []);

  if (isLoading) {
    return <PreLoader />;
  }
  const { organizationId, organizationName } = selectedCard;

  const getSelectedItem = (value) => {
    const item = organisations.find((opt) => {
      if (opt.id === value.organizationId) {
        return opt;
      }
      return undefined;
    });
    return item || {};
  };

  return (
    <Grid container>
      <Grid item xs={12} md={10}>
        <Formik
          enableReinitialize
          validationSchema={Step1Validation}
          initialValues={{
            organizationId: organizationId || '',
            organizationName: organizationName || '',
          }}
          onSubmit={async (values) => {
            const payload = { ...values };
            setSelectedCard(payload);
            handleNext();
          }}
        >
          {({ isSubmitting, handleSubmit, setFieldValue, errors, values }) => {
            return (
              <Form>
                <Box pb={2}>
                  <Autocomplete
                    name="organizationName"
                    defaultValue={() => getSelectedItem(values)}
                    autoHighlight
                    options={organisations}
                    includeInputInList
                    getOptionLabel={(option) => option.name || ''}
                    fullWidth
                    clearOnBlur
                    clearOnEscape
                    selectOnFocus
                    freeSolo
                    disabled={isSubmitting}
                    onChange={(e, org) => {
                      setFieldValue('organizationId', org ? org.id : '');
                      setFieldValue('organizationName', org ? org.name : '');
                    }}
                    renderInput={(params) => (
                      <Field
                        component={TextField}
                        {...params}
                        name="organizationName"
                        label="Supported Nonprofit (start typing for nonprofits)"
                        variant="outlined"
                        error={
                          !values.organizationId && !!errors.organizationId
                        }
                        helperText={
                          !values.organizationId && errors.organizationId
                        }
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box pb={2}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    size="large"
                  >
                    {isSubmitting ? <CircularProgress size={24} /> : 'Continue'}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

Step1.propTypes = {
  selectedCard: PropTypes.shape({
    minAmount: PropTypes.number,
    maxAmount: PropTypes.number,
    organizationId: PropTypes.string,
    organizationName: PropTypes.string,
  }).isRequired,
  organisations: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleNext: PropTypes.func.isRequired,
  getOrgs: PropTypes.func.isRequired,
  setSelectedCard: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getOrgs: () => dispatch(orgs.actions.getOrgs()),
  setSelectedCard: (payload) =>
    dispatch(cards.actions.setSelectedCard(payload)),
});

const mapStateToProps = (state) => ({
  selectedCard: cards.selectors.selectSelectedCard(state),
  organisations: orgs.selectors.selectOrgs(state),
  isLoading: orgs.selectors.selectIsLoading(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
