import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { TransactionTable } from '../../custom';
import { businesses, cards, checkout, enterprise } from '../../../state';
import CardModal from './CardModal';

const CardsSold = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSending = useSelector(enterprise.selectors.selectIsLoading);
  const step = useSelector(checkout.selectors.selectStep);

  const setStep = (payload) => dispatch(checkout.actions.setStep(payload));
  const setSelectedCard = (payload) =>
    dispatch(cards.actions.setSelectedCard(payload));
  const giftCardConfigDetail = useSelector(
    businesses.selectors.selectCardDetails
  );
  const [showDialog, setShowDialog] = useState(false);

  const closeDialog = () => {
    setStep(0);
    setShowDialog(false);
    setSelectedCard({});
  };

  const [params, setParams] = useState({ page: 1, pageSize: 5 });
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  // const [activeRowEmail, setActiveRowEmail] = useState(true);

  const handleGetList = async () => {
    setIsLoading(true);
    const response = await dispatch(enterprise.actions.getGiftCards(params));
    if (response.payload) {
      setData(response.payload.data || []);
      setTotal(response.payload.total || 0);
    }
    setIsLoading(false);
  };
  React.useEffect(() => {
    handleGetList();
  }, ['', JSON.stringify(params)]);

  React.useEffect(() => {
    if (step === 3) handleGetList();
  }, [step]);

  React.useEffect(() => {
    setParams({ page: 1, pageSize: 5 });
    setTimeout(handleGetList);
  }, [giftCardConfigDetail]);

  const search = () => {
    setParams({ ...params, keyword });
  };

  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, pageSize: event.target.value, page: 1 });
  };

  const cols = [
    t('transactionDate'),
    t('deliverAt'),
    t('merchant'),
    t('firstName'),
    t('lastName'),
    t('email'),
    t('order#'),
    t('giftCardCode'),
    t('currentBalance'),
    '',
  ];
  const rowKeys = [
    'createdAt',
    'deliverAt',
    'merchantName',
    'giftFirstName',
    'giftLastName',
    'giftEmail',
    'orderNumber',
    'code',
    'balance',
    'id',
  ];

  const addFunds = async (giftCard) => {
    const payload = {
      ...giftCard,
      cardNumber: giftCard.Code,
    };
    await setSelectedCard(payload);
    setShowDialog(true);
  };

  const mods = {
    createdAt: (val) => moment(`${val}Z`).format('MMMM DD YYYY, HH:mm:ss'),
    deliverAt: (val) => (val ? moment(`${val}Z`).format('MMMM DD YYYY') : ''),
    balance: (val, row) =>
      `${t('cardValue', {
        amount: val.toFixed(2),
        formatParams: {
          amount: { currency: row.currency || 'USD' },
        },
      })}`,
    id: (id, row) => (
      <Button
        disabled={isSending}
        onClick={() => addFunds(row)}
        variant="contained"
        color="primary"
        style={{ width: 95, padding: 6 }}
      >
        {t('addFunds')}
      </Button>
    ),
  };

  const pagination = {
    total,
    pageSize: params.pageSize,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const searchBox = () => (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item style={{ minWidth: 300 }}>
        <Box mx={1}>
          <TextField
            value={keyword}
            fullWidth
            type="string"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            name="keyword"
            onChange={(val) => {
              setKeyword(val.target.value);
            }}
          />
        </Box>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={search}
          type="submit"
        >
          {t('search')}
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={4}>
      <Grid container item xs={12}>
        <TransactionTable
          cols={cols}
          rowKeys={rowKeys}
          data={data}
          mods={mods}
          isLoading={isLoading}
          noDataMsg={t('noData')}
          pagination={pagination}
          searchBox={searchBox}
        />
      </Grid>
      <Dialog
        open={showDialog}
        onClose={closeDialog}
        scroll="paper"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>{t('addFunds')}</DialogTitle>
        <DialogContent dividers>
          <CardModal />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default CardsSold;
