import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { businesses, checkout, auth } from '../../../state';

import SEO from '../../seo';
import { Content } from '../../layouts';
import TransactionDetails from '../Checkout/TransactionDetails';
import SelectGiftCardImage from '../CardDetails/SelectGiftCardImage';
import BulkCheckout from './BulkCheckout';

const CardDetails = ({ data }) => {
  const { i18n } = useTranslation();
  const business = useSelector(businesses.selectors.selectBusiness);
  const dispatch = useDispatch();
  const setFormData = (payload) =>
    dispatch(checkout.actions.setFormData(payload));
  const user = useSelector(auth.selectors.selectUser);

  React.useEffect(() => {
    if (business && business.culture) {
      i18n.changeLanguage(business.culture);
    }
    setFormData({
      businessId: business.businessId || '',
      id: business.id || '',
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      currency: business.currency,
      data: data.map((r) => ({
        firstName: r['First Name'],
        lastName: r['Last Name'],
        email: r.Email,
        amount: r.Amount,
        giftMessage: r['Personal Message'],
        deliverAt: r['Send Date (YYYY-MM-DD)']
          ? moment(r['Send Date (YYYY-MM-DD)'], ['YYYY-MM-DD', 'YYYY/MM/DD'])
              .add(1, 'hour')
              .utc()
          : '',
      })),
    });
  }, [business]);

  const { name, isWhitelabel } = business;
  const checkoutStep = useSelector(checkout.selectors.selectStep);
  return (
    <>
      <SEO title={`${name}`} />
      <Content
        style={{ minHeight: !isWhitelabel && 'calc(100vh - 64px - 196px)' }}
        md={12}
      >
        {checkoutStep === 1 && <SelectGiftCardImage />}
        {(checkoutStep === 0 || checkoutStep === 2) && (
          <BulkCheckout data={data} />
        )}
        {checkoutStep === 3 && <TransactionDetails />}
      </Content>
    </>
  );
};

CardDetails.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      'First Name': PropTypes.string,
      'Last Name': PropTypes.string,
      Email: PropTypes.string,
      Amount: PropTypes.number,
    })
  ).isRequired,
};
export default CardDetails;
