import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Divider,
  Grid,
  Tabs,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TabContext, TabPanel } from '@material-ui/lab';
import SwipeableViews from 'react-swipeable-views/lib/SwipeableViews';
import SEO from '../../seo';
import HistoryList from './HistoryList';
import StyledTab from '../components/StyledTab';
import BulkIssueHistoryList from './BulkIssueHistoryList';
import Redemption from './Redemption';

const History = () => {
  const { t } = useTranslation();
  const [formTab, setTab] = useState(0);

  return (
    <Container disableGutters>
      <SEO title="Transaction History" />
      <Box pb={2}>
        <Typography variant="h6">{t('transactionHistory')}</Typography>
      </Box>
      <Divider />
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Tabs
            value={formTab}
            onChange={(event, newValue) => {
              setTab(newValue);
            }}
            TabIndicatorProps={{ style: { background: '#0969da' } }}
            aria-label="Select settings tab"
          >
            <StyledTab
              disableRipple
              id="initialSetup"
              aria-controls="values"
              label={t('giftCards')}
            />
            <StyledTab
              disableRipple
              id="redemption"
              aria-controls="values"
              label={t('redemption.redemption')}
            />
            <StyledTab
              disableRipple
              id="searchUpdate"
              aria-controls="values"
              label={t('credits')}
            />
          </Tabs>
        </Grid>
      </Grid>
      <Divider />
      <TabContext value={formTab.toString()}>
        <SwipeableViews index={formTab} onChangeIndex={setTab}>
          <TabPanel value={formTab.toString()} index={0}>
            <HistoryList />
          </TabPanel>
          <TabPanel value={formTab.toString()} index={1}>
            {formTab === 1 && <Redemption />}
          </TabPanel>
          <TabPanel value={formTab.toString()} index={2}>
            <BulkIssueHistoryList />
          </TabPanel>
        </SwipeableViews>
      </TabContext>
    </Container>
  );
};

export default History;
