import React, { useEffect, useState } from 'react';
import {
  Grid,
  CircularProgress,
  Button,
  Box,
  MenuItem,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useDispatch } from 'react-redux';
import CustomInputField from '../../custom/CustomInputField';
import LogoSection from './LogoSection';
import listOfStates from '../../../../misc/listOfStates.json';
import { enterprise } from '../../../state';

const initValue = {
  name: '',
  website: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  imageUrl: '',
};

// eslint-disable-next-line react/prop-types
const DetailsSection = ({ infoEnterprise }) => {
  const dispatch = useDispatch();
  const [enterpriseData, setEnterprise] = useState({});
  const [key, setKey] = useState(Math.random());
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (infoEnterprise) {
      setEnterprise(infoEnterprise);
      // eslint-disable-next-line react/prop-types
      setImageUrl(infoEnterprise.imageUrl);
    } else {
      setEnterprise(initValue);
    }
    setKey(Math.random());
  }, [infoEnterprise]);

  const handleSubmitForm = async () => {
    setLoading(true);
    try {
      const formData = {
        id: enterpriseData.id,
        imageUrl,
      };
      await dispatch(enterprise.actions.updateEnterpriseImage(formData));
      await dispatch(enterprise.actions.getInfo());
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      key={key}
    >
      <Grid item xs={12} md={4}>
        <Box pb={3}>
          <LogoSection
            name={enterpriseData.name}
            logoUrl={enterpriseData.imageUrl}
            setImageUrl={setImageUrl}
          />
        </Box>
        <Formik
          enableReinitialize
          initialValues={enterpriseData}
          onSubmit={async () => {}}
        >
          {({ setFieldValue }) => {
            return (
              <Form>
                <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    disabled
                    label="Enterprise Name"
                    name="name"
                    variant="outlined"
                  />
                </Box>
                <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    label="Address"
                    disabled
                    name="address"
                    variant="outlined"
                  />
                </Box>
                <Box pb={2}>
                  <Grid container display="row" spacing={2}>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        capitalize
                        disabled
                        fullWidth
                        label="City"
                        name="city"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={TextField}
                        label="State"
                        select
                        name="state"
                        variant="outlined"
                        disabled
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      >
                        {Object.keys(listOfStates).map((state) => (
                          <MenuItem
                            key={state}
                            value={state}
                            onClick={() => setFieldValue('state', state)}
                          >
                            {state}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        label="Zip code"
                        name="zip"
                        disabled
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{
                        //   inputProps: {
                        //     maxLength: '5',
                        //   },
                        // }}
                      />
                    </Grid>
                  </Grid>
                  <Box pt={2}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      disabled
                      label="Country"
                      name="country"
                      variant="outlined"
                    />
                  </Box>
                </Box>
                <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    disabled
                    label="Website"
                    name="website"
                    variant="outlined"
                  />
                </Box>
                <Box pb={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={handleSubmitForm}
                    size="large"
                  >
                    {loading ? <CircularProgress size={24} /> : 'Save Details'}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default DetailsSection;
