import React, { useEffect } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Content } from '../../layouts';
import SEO from '../../seo';
import { businesses, checkout } from '../../../state';
import TermsAndConditions from '../Checkout/TermsAndConditions';
import BulkOrderDetails from './BulkOrderDetails';
import BulkOrderBreakdown from './BulkOrderBreakdown';

const useStyles = makeStyles((theme) => ({
  squareLogo: {
    height: '20px',
  },
  stripeLogo: {
    maxHeight: '30px',
  },
  cloverLogo: {
    maxHeight: '20px',
  },
  item1: {
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  item2: {
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
}));

// eslint-disable-next-line react/prop-types
const BulkCheckout = ({ data }) => {
  const { t } = useTranslation();
  const business = useSelector(businesses.selectors.selectBusiness);
  const { name } = business;
  const classes = useStyles();
  const dispatch = useDispatch();
  const formData = useSelector(checkout.selectors.selectFormData);
  const setFormData = (payload) =>
    dispatch(checkout.actions.setFormData(payload));

  const {
    // id,
    businessId,
    giftCardImageId,
    occasions,
  } = business;
  useEffect(() => {
    if (businessId && business && !giftCardImageId) {
      const filteredOccasions = occasions
        .map((o) => ({
          ...o,
          images: o.images.filter((i) => i.status === 0),
        }))
        .filter((o) => o.status === 0 && o.images.length > 0);
      if (
        filteredOccasions &&
        filteredOccasions.length === 1 &&
        filteredOccasions[0].images.length === 1
      ) {
        setFormData({
          ...formData,
          imageUrl: filteredOccasions[0].images[0].url,
          occasionId: filteredOccasions[0].id,
          giftCardImageId: filteredOccasions[0].images[0].id,
        });
      }
    }
  }, [business]);

  return (
    <>
      <SEO title="Checkout" />
      <Content style={{ minHeight: 'calc(100vh - 64px - 196px)' }}>
        <Box pb={4}>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Box my={3}>
              <Typography display="block" align="center" variant="h5">
                {name}
              </Typography>
              <Typography display="block" align="center" variant="subtitle1">
                {t('confirmOrderDetail')}
              </Typography>
            </Box>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={6}
          >
            <Grid item xs={12} sm={5} className={classes.item2}>
              <BulkOrderDetails data={data} />
            </Grid>
            <Grid item xs={12} sm={7} className={classes.item1}>
              <Box mb={3}>
                <BulkOrderBreakdown data={data} />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <Box mt={1}>
                <TermsAndConditions />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Content>
    </>
  );
};
export default BulkCheckout;
