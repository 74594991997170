import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Box,
  Grid,
  Typography,
  TableCell as MuiTableCell,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  Button,
  withStyles,
} from '@material-ui/core';
import { CustomImgCard } from '../../custom';
import defaultCardImage from '../../../images/defaultGiftCard.jpg';
import { cards } from '../../../state';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '0 0 10px 0',
  },
})(MuiTableCell);

const Step3 = ({ orderDetails, handleNext }) => {
  const {
    name,
    imageUrl,
    amount,
    recipients,
    organizationName,
    giveBack,
  } = orderDetails;

  const totalAmount = amount * recipients.length;
  const giveBackAmount = (giveBack * totalAmount).toFixed(2);

  return (
    <Grid container direction="row" spacing={4}>
      <Grid item md={6}>
        <Box pb={2}>
          <CustomImgCard
            name={name || 'default image'}
            imageUrl={imageUrl || `${defaultCardImage}`}
            elevation={0}
          />
        </Box>
        <Box pb={2} align="center">
          <Typography variant="body2" component="p">
            <b>
              {`$${giveBackAmount}`} will go to {`${organizationName}`}
            </b>
          </Typography>
        </Box>
      </Grid>
      <Grid item md={6}>
        <Typography gutterBottom variant="h6">
          <b>Charge breakdown:</b>
        </Typography>
        <TableContainer>
          <Table aria-label="Charge breakdown">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Gift card value
                  </Typography>
                </TableCell>
                <TableCell align="right">{`$${amount}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Total recipients
                  </Typography>
                </TableCell>
                <TableCell align="right">{`${recipients.length}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography variant="body2">
                    <b>Total Amount </b>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <b>{`$${totalAmount}`}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box pb={2}>
          <Typography variant="caption" color="textSecondary" component="p">
            Note: Total amount shown will be deducted from your Account Balance.
          </Typography>
        </Box>
        <Button
          fullWidth
          variant="contained"
          onClick={() => handleNext()}
          color="primary"
        >
          Pay ${`${totalAmount}`}
        </Button>
      </Grid>
    </Grid>
  );
};
Step3.propTypes = {
  orderDetails: PropTypes.shape({
    name: PropTypes.string,
    imageUrl: PropTypes.string,
    amount: PropTypes.number,
    giveBack: PropTypes.number,
    recipients: PropTypes.arrayOf(PropTypes.string),
    organizationName: PropTypes.string,
  }).isRequired,
  handleNext: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  orderDetails: cards.selectors.selectSelectedCard(state),
});

export default connect(mapStateToProps)(Step3);
