/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CardMedia, Checkbox } from '@material-ui/core';
import { TransactionTable } from '../../custom';
import { enterprise, businesses } from '../../../state';

const SelectGiftCardConfig = () => {
  const giftCardConfigs = useSelector(
    enterprise.selectors.selectGiftCardConfigs
  );
  const giftCardConfigDetail = useSelector(
    businesses.selectors.selectCardDetails
  );

  const dispatch = useDispatch();

  const [params, setParams] = useState({ page: 1, pageSize: 5 });
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(
      giftCardConfigs.slice(
        (params.page - 1) * params.pageSize,
        params.page * params.pageSize
      )
    );
  }, [JSON.stringify(giftCardConfigs), JSON.stringify(params)]);
  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, pageSize: event.target.value, page: 1 });
  };
  const pagination = {
    total: giftCardConfigs.length,
    pageSize: params.pageSize,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const mods = {
    id: (val, row) => (
      <Checkbox
        color="primary"
        checked={val === giftCardConfigDetail.id}
        onClick={() => dispatch(businesses.actions.getGiftCardConfig(row?.id))}
      />
    ),
    imageUrl: (val, row) => (
      <CardMedia
        src={val}
        component="img"
        alt={row.merchantName}
        style={{ width: 100, maxHeight: 50, margin: 'auto' }}
      />
    ),
  };

  const { t } = useTranslation();
  const cols = [t('merchantName'), t('image'), t('selected')];
  const rowKeys = ['merchantName', 'imageUrl', 'id'];
  return (
    <TransactionTable
      cols={cols}
      rowKeys={rowKeys}
      data={data}
      mods={mods}
      noDataMsg={t('noGiftCard')}
      pagination={pagination}
    />
  );
};

export default SelectGiftCardConfig;
