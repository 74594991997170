import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Avatar,
  makeStyles,
  Box,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
// import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { enterprise } from '../../../state';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const LogoSection = ({ logoUrl, name, setImageUrl }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const fileInput = React.useRef(null);
  const [previewImg, setPreviewImg] = React.useState('');
  const [errors, setErrors] = React.useState('');
  const [imgIsLoading, setImgLoading] = React.useState(false);
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    const formData = new FormData();
    setImgLoading(true);
    formData.append('file', file);
    reader.readAsDataURL(file);
    if (file.size > 512000) {
      setErrors('*File cannot exceed 500KB.');
      setImgLoading(false);
    } else {
      reader.onloadend = async () => {
        const res = await dispatch(
          enterprise.actions.setEnterpriseImage(formData)
        );
        if (res.error) {
          fileInput.current.value = '';
          setErrors(res.error.message);
          setPreviewImg('');
          setImageUrl(logoUrl || '');
        }
        if (res.payload) {
          setErrors('');
          setPreviewImg(reader.result);
          setImageUrl(res.payload.imageUrl);
        }
        setImgLoading(false);
      };
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={8}>
          <Box
            flexDirection="row"
            display="flex"
            alignItems="center"
            pb={{ xs: 3, sm: 0 }}
          >
            <Box>
              <Avatar
                alt={name}
                src={previewImg || logoUrl}
                className={classes.large}
              />
            </Box>
            <Box pl={1}>
              <Typography variant="body2">{t('yourLogo')}</Typography>
              <Typography variant="caption" color="textSecondary">
                PNG or JPG (200px x 200px).
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box align="right">
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              component="label"
            >
              {imgIsLoading ? <CircularProgress size={24} /> : t('upload')}
              <input
                id="file"
                type="file"
                ref={fileInput}
                hidden
                accept="image/*"
                name="file"
                disabled={imgIsLoading}
                onChange={handleImageUpload}
              />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box ml={1} width="100%">
        <Typography variant="caption" color={errors ? 'error' : 'textPrimary'}>
          {errors}
        </Typography>
      </Box>
    </>
  );
};

LogoSection.defaultProps = {
  name: '',
  logoUrl: '',
  setImageUrl: null,
};

LogoSection.propTypes = {
  name: PropTypes.string,
  logoUrl: PropTypes.string,
  setImageUrl: PropTypes.func,
};

export default LogoSection;
