import React from 'react';
import {
  Typography,
  Box,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Grid,
  IconButton,
  Container,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash/fp';

import SEO from '../../seo';
import { cards } from '../../../state';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import OrderDetails from './OrderDetails';

const getSteps = () => {
  return ['Card & Amount', 'Nonprofit', 'Recipients', 'Confirm and Pay'];
};

const SendToCustomers = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const cardList = useSelector((state) => cards.selectors.selectCards(state));
  React.useEffect(() => {
    if (isEmpty(cardList)) {
      dispatch(cards.actions.getCards());
    }
    dispatch(cards.actions.emptySelectedCard());
  }, []);

  const steps = getSteps();
  const firstAndLastStep = activeStep !== 0 && activeStep !== 4;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Container disableGutters>
      <SEO title="Send Gift Cards" />
      <Box pb={2}>
        <Grid container alignItems="center">
          {activeStep !== 0 && (
            <Grid item>
              <IconButton
                aria-label="Back"
                onClick={activeStep === 4 ? handleReset : handleBack}
                style={{ padding: 6 }}
              >
                <ArrowBack fontSize="small" />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <Box pl={activeStep !== 0 ? 2 : 0}>
              <Typography variant="h6">Send To Customers</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      {!mobile && (
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      <Box py={!mobile ? 0 : 2}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={firstAndLastStep ? 6 : 12}>
            {activeStep === 0 && (
              <Step0 handleNext={handleNext} cardList={cardList} />
            )}
            {activeStep === 1 && <Step1 handleNext={handleNext} />}
            {activeStep === 2 && <Step2 handleNext={handleNext} />}
            {activeStep === 3 && <Step3 handleNext={handleNext} />}
            {activeStep === 4 && <Step4 setActiveStep={setActiveStep} />}
          </Grid>
          {!mobile && firstAndLastStep && (
            <Grid item md={6}>
              <Box align="center">
                <OrderDetails />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default SendToCustomers;
