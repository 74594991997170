import React from 'react';
import { connect } from 'react-redux';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { types, categories, cards } from '../../../state';

const useStyles = makeStyles({
  root: {
    borderBottom: 'none',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    textTransform: 'none',
  },
  skeleton: {
    marginBottom: '-8px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
});

const TypeFilters = ({
  setSelectedCategoryFilter,
  setSelectedTypeFilter,
  getCategories,
  filterByType,
  selectedType,
  typesList,
  isLoading,
}) => {
  const classes = useStyles();
  const handleTypeClick = (typeId) => {
    setSelectedCategoryFilter('');
    getCategories(typeId);
    setSelectedTypeFilter(typeId);
    filterByType(typeId);
  };
  return (
    <ToggleButtonGroup aria-label="Filter by type">
      {typesList.map(({ name, id }) =>
        !name || isLoading ? (
          <Skeleton
            className={classes.skeleton}
            key={id}
            width={100}
            height={48}
            variant="text"
          />
        ) : (
          <ToggleButton
            variant="contained"
            color="primary"
            size="small"
            className={classes.root}
            selected={selectedType === id}
            onClick={() => handleTypeClick(id)}
            key={id}
            value={id}
            aria-label={name}
          >
            {name}
          </ToggleButton>
        )
      )}
    </ToggleButtonGroup>
  );
};

TypeFilters.defaultProps = {
  typesList: [],
  selectedType: '',
};

TypeFilters.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  selectedType: PropTypes.string,
  setSelectedCategoryFilter: PropTypes.func.isRequired,
  setSelectedTypeFilter: PropTypes.func.isRequired,
  filterByType: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  typesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      isDefault: PropTypes.bool,
    })
  ),
};

const mapDispatchToProps = (dispatch) => ({
  fetchCards: () => dispatch(cards.actions.fetchCards()),
  fetchTypes: () => dispatch(types.actions.fetchTypes()),
  getCategories: (typeId) => dispatch(categories.actions.getCategories(typeId)),
  // setters
  setSelectedCategoryFilter: (categoryId) =>
    dispatch(categories.actions.setSelectedCategoryFilter(categoryId)),
  setSelectedTypeFilter: (typeId) =>
    dispatch(types.actions.setSelectedTypeFilter(typeId)),
  // filters
  filterByType: (typeId) => dispatch(cards.actions.filterByType(typeId)),
});

const mapStateToProps = (state) => ({
  selectedType: types.selectors.selectSelectedType(state),
  typesList: types.selectors.selectFilterTypes(state),
  isLoading: types.selectors.selectIsLoading(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(TypeFilters);
