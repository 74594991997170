import React from 'react';
import { Typography, Box, Divider, Container } from '@material-ui/core';

import CustomersList from './CustomersList';
import SEO from '../../seo';

const ManageCustomers = () => {
  return (
    <Container disableGutters>
      <SEO title="Manage Customers" />
      <Box pb={2}>
        <Typography variant="h6">Manage Customers</Typography>
      </Box>
      <Divider />
      <Box py={2}>
        <CustomersList />
      </Box>
    </Container>
  );
};

export default ManageCustomers;
