import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { TransactionTable } from '../../custom';
import { businesses, enterprise } from '../../../state';

const BulkIssueHistoryList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const giftCardConfigDetail = useSelector(
    businesses.selectors.selectCardDetails
  );

  const [params, setParams] = useState({ page: 1, pageSize: 5 });
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const { currency } = useSelector(businesses.selectors.selectBusiness);

  const handleGetList = async () => {
    setIsLoading(true);
    const response = await dispatch(
      enterprise.actions.getBulkTransactions(params)
    );
    if (response.payload) {
      setData(response.payload.data || []);
      setTotal(response.payload.total || 0);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setParams({ page: 1, pageSize: 5 });
    setTimeout(handleGetList);
  }, [giftCardConfigDetail]);

  useEffect(() => {
    handleGetList();
  }, [JSON.stringify(params)]);

  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, pageSize: event.target.value, page: 1 });
  };

  const cols = [t('transactionDate'), t('credit'), t('debit'), t('note')];
  const rowKeys = ['createdAt', 'amount', 'id', 'note'];

  const mods = {
    createdAt: (val) => moment(`${val}Z`).format('MMMM DD YYYY, HH:mm:ss'),
    amount: (val, row) =>
      row.type === 0 &&
      `${t('cardValue', {
        amount: val.toFixed(2),
        formatParams: {
          amount: { currency: currency || 'USD' },
        },
      })}`,
    id: (val, row) =>
      row.type !== 0 &&
      `${t('cardValue', {
        amount: row.amount.toFixed(2),
        formatParams: {
          amount: { currency: currency || 'USD' },
        },
      })}`,
  };

  const pagination = {
    total,
    pageSize: params.pageSize,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  return (
    <Grid container spacing={4}>
      <Grid container item xs={12}>
        <TransactionTable
          cols={cols}
          rowKeys={rowKeys}
          data={data}
          mods={mods}
          isLoading={isLoading}
          noDataMsg={t('noData')}
          pagination={pagination}
        />
      </Grid>
    </Grid>
  );
};

export default BulkIssueHistoryList;
