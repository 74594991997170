import * as Yup from 'yup';

const Step0Validation = (maxAmount, minAmount) =>
  Yup.object().shape({
    amount: Yup.number()
      .typeError('Amount must be a number')
      .min(minAmount, `Minumum amount $${minAmount}`)
      .max(maxAmount, `Maximum amount allowed is $${maxAmount}`)
      .required('Required'),
    qty: Yup.number().required('Required'),
  });
const Step1Validation = () =>
  Yup.object().shape({
    organizationId: Yup.string().required('Required'),
  });
// eslint-disable-next-line import/prefer-default-export
export { Step0Validation, Step1Validation };
